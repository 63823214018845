import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "components";
import { useLocation } from "@reach/router";
import { last } from "lodash";
import { getROIImage, removeROI } from "api";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const RegionInterest = ({ data, loading, isUploading, onRemove }) => {
  return (
    <Container>
      {loading ? (
        <SkeletonTheme color="#202020" highlightColor="#444">
          <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
          <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
          <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
          <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
          <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
        </SkeletonTheme>
      ) : (
        <div className="d-flex flex-wrap mt-4">
          {data.map((item, index) => (
            <ROI
              key={item.roi_hash}
              hash={item.roi_hash}
              index={index + 1}
              onRemove={onRemove}
            />
          ))}
          {isUploading && (
            <RegionContainer className="d-flex flex-column m-2">
              <RingLoader
                color="white"
                size={80}
                css={css`
                  margin: auto;
                `}
              />
            </RegionContainer>
          )}
        </div>
      )}
    </Container>
  );
};

const ROI = ({ hash, index, onRemove }) => {
  const location = useLocation();

  const [isLoadingThumb, setIsLoadingThumb] = useState(false);
  const [thumbSrc, setThumbSrc] = useState("");
  const [isRemoving, setIsRemoving] = useState(false);

  const fetchThumb = async (params) => {
    setIsLoadingThumb(true);
    const { success, payload } = await getROIImage(
      last(location.pathname.split("/")),
      params
    );
    if (success) {
      setThumbSrc(payload);
    } else {
      setThumbSrc("");
    }
    setIsLoadingThumb(false);
  };

  useEffect(() => {
    if (hash) {
      fetchThumb(hash);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  const handleRemove = async () => {
    setIsRemoving(true);
    const res = await removeROI(last(location.pathname.split("/")), index);
    setIsRemoving(false);
    if (res.success) {
      onRemove(res.payload);
    }
  };

  return (
    <RegionContainer className="d-flex flex-column m-2">
      {isLoadingThumb ? (
        <RingLoader
          color="white"
          size={80}
          css={css`
            margin: auto;
          `}
        />
      ) : (
        <>
          <RegionImg src={`data:image/jpeg;base64,${thumbSrc}`} />
          <div className="d-flex justify-content-center mt-2">
            <Button
              size="small"
              width="120px"
              className="my-auto"
              isLoading={isRemoving}
              onClick={handleRemove}
            >
              Remove
            </Button>
          </div>
        </>
      )}
    </RegionContainer>
  );
};

const Container = styled.div``;

const RegionContainer = styled.div`
  width: 160px;
`;

const RegionImg = styled.img`
  width: 160px;
  height: 160px;
  object-fit: contain;
  border: 1px solid #e2e2e2;
`;
