import React from "react";
import styled from "styled-components";
import { DETAIL_TABS } from "config";

export const TabBar = ({ value = "INFORMATION", onChange = () => {} }) => {
  return (
    <Container className="d-flex">
      <Heading className="my-auto mr-auto">{value}</Heading>
      {DETAIL_TABS.map(({ label, icon }) => (
        <Icon
          onClick={() => onChange(label)}
          active={label === value}
          key={label}
        >
          {icon}
        </Icon>
      ))}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.palette.backgrounds};
  padding: 0 24px;
  height: 50px;
  margin: 2px;
`;

const Heading = styled.div`
  color: white;
  font-size: 20px;
  text-transform: uppercase;
`;

const Icon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${(props) => (props.active ? "white" : "")};
  cursor: pointer;
  transition: all 300ms ease;
  color: ${(props) =>
    props.active ? props.theme.palette.backgrounds : "white"};
  margin: auto 4px;
  display: flex;
  svg {
    margin: auto;
  }
  &:hover {
    opacity: 0.7;
    background: #eeeeee30;
  }
`;
