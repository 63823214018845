import React, { useState, useEffect } from "react";
import { Modal, TextArea, Button } from "components";
import { uploadURLs } from "api";
import RiseLoader from "react-spinners/RiseLoader";
import { css } from "@emotion/react";
import { useRawInput } from "hooks";

export const UploadURLModal = ({
  open,
  onClose,
  onUploadFinish = () => {},
}) => {
  const [state, setState] = useState("init");
  const [result, setResult] = useState("");
  const content = useRawInput();

  const handleUpload = async () => {
    if (content.value.trim()) {
      setState("uploading");
      const res = await uploadURLs(
        content.value.split("\n").length > 1
          ? content.value
          : content.value + "\n"
      );
      setState("uploaded");
      if (res.payload.success) {
        setResult("Uploaded successfully!");
        onUploadFinish();
      } else {
        setResult("Error!");
      }
    }
  };

  useEffect(() => {
    if (open) {
      setState("init");
      content.setValue("");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      isCloseDisabled={state === "uploading"}
      title={
        state === "uploading"
          ? "Uploading..."
          : state === "init"
          ? "Upload URLs"
          : state === "uploaded"
          ? "Upload URLs"
          : ""
      }
    >
      {state === "uploading" ? (
        <div className="d-flex py-4 my-4">
          <RiseLoader
            css={css`
              margin: auto;
            `}
            color="#006280"
            size={20}
            margin={2}
          />
        </div>
      ) : state === "init" ? (
        <>
          <TextArea {...content} />

          <div className="d-flex mt-4">
            <Button
              size="normal"
              buttonTheme="dark"
              width="120px"
              className="ml-auto"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="normal"
              buttonTheme="primary"
              width="120px"
              className="ml-4"
              disabled={!content.value}
              onClick={handleUpload}
            >
              Ok
            </Button>
          </div>
        </>
      ) : state === "uploaded" ? (
        <>
          <h2>{result}</h2>
          <div className="d-flex mt-4">
            <Button
              size="normal"
              buttonTheme="primary"
              className="ml-auto"
              width="120px"
              onClick={onClose}
            >
              Ok
            </Button>
          </div>
        </>
      ) : null}
    </Modal>
  );
};
