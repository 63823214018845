import React from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { LoginLayout } from "layouts";
import { Button, FormInput, Link } from "components";
import LogoImg from "assets/img/logo.png";
import { navigate } from "@reach/router";
import { register } from "api";
import Swal from "sweetalert2";

const LoginForm = styled.div`
  width: 428px;
  margin: 60px auto 0 auto;
`;
const Logo = styled.img`
  width: 600px;
  margin: auto auto 0 auto;
`;

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Please enter your name"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Please enter your email"),
  password: Yup.string()
    .required("Please enter your password")
    .matches(
      /^(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, and one number"
    ),
});

export const RegisterPage = () => {
  return (
    <LoginLayout>
      <Logo src={LogoImg} alt="logo" />
      <LoginForm>
        <Formik
          initialValues={{
            password: "",
            username: "",
            email: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const res = await register(values);
            if (res === "success") {
              Swal.fire({
                icon: "success",
                title: "Registered Successfully!",
              });
              navigate("/");
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormInput label="User Name" name="username" />
              <FormInput label="Email" name="email" />
              <FormInput label="Password" type="password" name="password" />
              <Button size="large" className="mt-3" isLoading={isSubmitting}>
                Register
              </Button>
            </Form>
          )}
        </Formik>
        <div className="d-flex mt-3">
          <Link className="ml-auto" href="/">
            Login here
          </Link>
        </div>
      </LoginForm>
    </LoginLayout>
  );
};
