import React from "react";
import styled from "styled-components";
import {
  FaFileAudio,
  FaFileVideo,
  FaTrashAlt,
  FaFileAlt,
} from "react-icons/fa";
import { HiDuplicate } from "react-icons/hi";
import {
  AiFillFileImage,
  AiFillFileText,
  AiFillFileExclamation,
} from "react-icons/ai";
import RingLoader from "react-spinners/RingLoader";
import ClockLoader from "react-spinners/ClockLoader";
import { css as EmotionCSS } from "@emotion/react";
import { BiLink } from "react-icons/bi";
import { HiCheckCircle } from "react-icons/hi";
import { RiErrorWarningFill } from "react-icons/ri";
import { BsFillCaretDownFill } from "react-icons/bs";

export const FileItem = ({ file, onClick, expanded, onRemove }) => {
  const { parent, category, name, state, type } = file;
  return (
    <File
      parent={parent === undefined ? false : true}
      onClick={() => onClick(file)}
    >
      <Icon category={category} type={type} />
      <Name
        className="ml-2 my-auto"
        data-for="upload-tooltip"
        data-tip={name}
        parent={parent === undefined ? false : true}
      >
        {name}
      </Name>
      {state === "canceled" && (
        <Canceled className="my-auto ml-3">canceled</Canceled>
      )}
      {state === "duplicateError" && (
        <Canceled className="my-auto ml-3">Already exists</Canceled>
      )}
      {expanded !== "" && (
        <ArrowIcon open={expanded === "expanded"}>
          <BsFillCaretDownFill size={12} />
        </ArrowIcon>
      )}
      <StateIcon state={state} onRemove={() => onRemove(file)} />
    </File>
  );
};

const File = styled.div`
  font-size: 16px;
  padding: 8px;
  display: flex;
  color: white;
  border-top: 1px solid ${(props) => props.theme.palette.items};
  transition: all 300ms ease;
  padding-left: ${(props) => (props.parent ? "40px" : "10px")};
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.palette.gray};
  }
`;

const IconButton = styled.div`
  color: white;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.7;
  }
`;
const Name = styled.div`
  max-width: ${(props) => (props.parent ? "360px" : "400px")};
  width: ${(props) => (props.parent ? "360px" : "400px")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 16px;
  margin-right: auto;
`;

const Canceled = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.palette.itemsHover};
  margin-right: 8px;
`;

const Icon = ({ type, category, className }) => {
  return (
    <IconWrapper className={className}>
      {type === "video" ? (
        <FaFileVideo color="white" />
      ) : type === "image" ? (
        <AiFillFileImage color="white" />
      ) : type === "audio" ? (
        <FaFileAudio color="white" />
      ) : type === "text" ? (
        <AiFillFileText color="white" />
      ) : category === "url" ? (
        <BiLink color="white" />
      ) : category === "file" ? (
        <FaFileAlt color="white" />
      ) : (
        <AiFillFileExclamation color="white" />
      )}
    </IconWrapper>
  );
};

const IconWrapper = styled.span`
  margin: auto 0;
  font-size: 18px;
`;

const StateIcon = ({ state, onRemove = () => {}, onUpload = () => {} }) => {
  return (
    <StateIconWrapper className="my-auto">
      <StateIconNormal>
        {state === "ready" ? (
          <RingLoader
            size={18}
            css={EmotionCSS`
                            display: flex;
                          `}
            color="#006280"
          />
        ) : state === "uploading" || state === "parsing" ? (
          <ClockLoader
            size={24}
            css={EmotionCSS`
                            display: flex;
                          `}
            color="#006280"
          />
        ) : state === "uploaded" || state === "parsed" ? (
          <HiCheckCircle color="#63ce6b" size={24} />
        ) : state === "duplicateError" ? (
          <HiDuplicate color="#FFF0A8" size={24} />
        ) : (
          <RiErrorWarningFill color="#ff8484" size={24} />
        )}
      </StateIconNormal>

      <StateIconHover>
        {state === "ready" ? (
          <FaTrashAlt color="#2a90af" size={20} onClick={onRemove} />
        ) : state === "canceled" ? (
          // <RiUploadCloudFill color="#2a90af" size={24} onClick={onUpload} />
          <RiErrorWarningFill color="#ff8484" size={24} />
        ) : state === "uploading" || state === "parsing" ? (
          <ClockLoader
            size={24}
            css={EmotionCSS`
                            display: flex;
                          `}
            color="#006280"
          />
        ) : state === "uploaded" || state === "parsed" ? (
          <HiCheckCircle color="#63ce6b" size={24} />
        ) : state === "duplicateError" ? (
          <HiDuplicate color="#FFF0A8" size={24} />
        ) : (
          <RiErrorWarningFill color="#ff8484" size={24} />
        )}
      </StateIconHover>
    </StateIconWrapper>
  );
};
const StateIconWrapper = styled(IconButton)``;
const StateIconNormal = styled.div`
  display: block;
  ${StateIconWrapper}:hover & {
    display: none;
  }
`;
const StateIconHover = styled.div`
  display: none;
  ${StateIconWrapper}:hover & {
    display: block;
  }
`;

const ArrowIcon = styled.div`
  transition: all 300ms ease;
  margin-right: 8px;
  transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0deg)")};
  color: white;
`;
