export const extractFilters = (
  categories,
  tags,
  targets = [],
  classes = []
) => {
  const catNameMap = {
    "global.exception": "Exceptions",
    "global.category": "Categories",
    "global.confirmation": "Confirmations",
    "global.feature": "Features",
    "global.brand": "Brands",
    "global.medium": "Mediums",
  };
  let filters = {};
  categories &&
    categories.forEach((cat) => {
      filters[catNameMap[cat]] = {
        category: cat,
        data: tags
          .filter((tag) => tag.name.includes(cat))
          .map((tag) => {
            return { ...tag, label: tag.name.split(".")[2] };
          }),
      };
    });
  filters["Advertisers"] = {
    category: "target",
    data: targets.filter((a) => a && a.length !== 0),
  };
  filters["Sub-Categories"] = {
    category: "class",
    data: classes.filter((a) => a && a.length !== 0),
  };
  return filters;
};
