/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { LoginLayout } from "layouts";
import { Button, FormInput, Link, Modal } from "components";
import LogoImg from "assets/img/logo.png";
import { navigate } from "@reach/router";
import { login } from "api";
import { useAuthContext, usePostContext, useUploadContext } from "contexts";
import { parse } from "query-string";
import { useLocation } from "@reach/router";
import { useModal } from "hooks";
import { clearAllCookies } from "utils";
// import { TiVendorMicrosoft } from "react-icons/ti";
// import { FcGoogle } from "react-icons/fc";
// import { API_URL } from "config";
import axios from "axios";
import { useCookies } from "react-cookie";

const LoginForm = styled.div`
  width: 428px;
  margin: 60px auto 0 auto;
`;
const Logo = styled.img`
  width: 600px;
  margin: auto auto 0 auto;
`;
const Error = styled.div`
  color: #ff8484;
  font-size: 20px;
`;

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Please enter your name"),
  password: Yup.string()
    .required("Please enter your password")
    .matches(
      /^(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, and one number"
    ),
});

export const LoginPage = () => {
  const { loginUser } = useAuthContext();
  const { initPosts } = usePostContext();
  const [error, setError] = useState("");
  const location = useLocation();
  const expireModal = useModal();
  const googleAuthModal = useModal();
  const { initUploads } = useUploadContext();
  const [cookes, setCookies, removeCookies] = useCookies([
    "remember_token",
    "session",
  ]);
  useEffect(() => {
    const parsed = parse(location.search);
    if (parsed.token_expire) {
      expireModal.openModal();
    } else if (parsed["google-auth-error"]) {
      googleAuthModal.openModal();
    }
    if (parsed.token && parsed.jwt) {
      localStorage.setItem("user", parsed.token);
      const authHeader = "Bearer " + parsed.token;
      axios.defaults.headers.common["Authorization"] = authHeader;
      clearAllCookies();
      initPosts();
      loginUser(true);
      initUploads();
      navigate("/home");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const onGoogleLogin = () => {
  //   window.location.href = `${API_URL}/api/google_login`;
  // };

  // const onMSLogin = () => {};

  return (
    <LoginLayout>
      <Logo src={LogoImg} alt="logo" />
      <LoginForm>
        <Formik
          initialValues={{
            password: "",
            username: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            setError("");
            try {
              const res = await login(values);
              if (res === "success") {
                clearAllCookies();
                initPosts();
                loginUser(true);
                initUploads();
                removeCookies();
                const parsed = parse(location.search);
                if (parsed.redirect) {
                  window.location.href = parsed.redirect;
                } else {
                  navigate("/home");
                }
              } else {
                setError("Invalid username or password");
              }
            } catch (error) {
              setError(error.message);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormInput label="User Name" name="username" />
              <FormInput label="Password" type="password" name="password" />
              {error && <Error className="mt-3">{error}</Error>}
              <Button
                size="large"
                type="submit"
                className="mt-3"
                isLoading={isSubmitting}
              >
                Login
              </Button>
            </Form>
          )}
        </Formik>
        <div className="d-flex mt-3">
          <Link href="/forgot-password">Forgot password?</Link>
          <Link className="ml-auto" href="/register">
            Sign up here
          </Link>
        </div>
        {/* <div className="d-flex mt-4 justify-content-center">
          <SocialButton onClick={onGoogleLogin}>
            <FcGoogle size={80} />
          </SocialButton>
          <SocialButton onClick={onMSLogin}>
            <TiVendorMicrosoft size={80} color="#2a90af" />
          </SocialButton>
        </div> */}
      </LoginForm>
      <Modal size="small" {...expireModal} title="Your session has expired">
        <div className="text-center">Please login again.</div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            width="120px"
            size="normal"
            onClick={() => expireModal.onClose()}
          >
            Ok
          </Button>
        </div>
      </Modal>
      <Modal
        size="small"
        {...googleAuthModal}
        title="Google Authentication failed"
      >
        <div className="text-center">Please login again.</div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            width="120px"
            size="normal"
            onClick={() => googleAuthModal.onClose()}
          >
            Ok
          </Button>
        </div>
      </Modal>
    </LoginLayout>
  );
};

// const SocialButton = styled.div`
//   width: 80px;
//   height: 80px;
//   margin: 0 20px;
//   cursor: pointer;
//   transition: all 300ms ease;
//   &:hover {
//     opacity: 0.7;
//   }
// `;
