import React, { useState } from "react";
import styled from "styled-components";
import { useField } from "formik";
import { Transition } from "react-transition-group";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

export const FormInput = ({
  className,
  label,
  placeholder,
  onBlur = () => {},
  ...props
}) => {
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(true);
  const [focused, setFocused] = useState(false);

  const handleBlur = (event) => {
    setFocused(false);
    onBlur(event);
  };

  const handleShowPassword = () => {
    setShowPassword((state) => !state);
  };
  return (
    <Container className={className}>
      <div className="d-flex position-relative">
        <Label focused={focused || field.value.length} htmlFor={field.name}>
          {label}
        </Label>
        <StyledInput
          id={field.name}
          aria-labelledby={field.name}
          onFocus={() => setFocused(true)}
          onBlur={handleBlur}
          aria-describedby={field.name}
          autoComplete={"new-password"}
          {...props}
          {...field}
          type={props.type === "password" && showPassword ? "password" : "text"}
        />
        <Underline focused={focused} />
        {props.type === "password" && (
          <EyeIcon>
            {showPassword ? (
              <BsEyeFill color="white" onClick={handleShowPassword} />
            ) : (
              <BsEyeSlashFill color="white" onClick={handleShowPassword} />
            )}
          </EyeIcon>
        )}
      </div>

      <Transition
        in={meta.touched && meta.error && meta.error.length > 0}
        timeout={500}
        unmountOnExit
        mountOnEnter
      >
        {(state) => (
          // state change: exited -> entering -> entered -> exiting -> exited
          <Validation role="alert" state={state}>
            {meta.error}
          </Validation>
        )}
      </Transition>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 24px 0 8px 0;
`;

const Label = styled.label`
  color: ${(props) =>
    props.focused ? props.theme.palette.secondary : "white"};
  position: absolute;
  top: 8px;
  left: 0;
  display: block;
  cursor: text;
  transform-origin: top left;
  transition: color 500ms ease;

  transition: transform 500ms ease, color 500ms ease;

  transform: ${(props) =>
    props.focused ? "scale(0.8) translateY(-30px)" : ""};
`;
const StyledInput = styled.input`
  display: block;
  color: white;
  box-shadow: inset 0 -2px 0 0 ${(props) => props.theme.palette.secondary};
  transition: background-color 5000s linear 0s;
  width: 100%;
  font-size: ${(props) => props.theme.font.size.l};
  line-height: ${(props) => props.theme.font.size.xl};
  height: 40px;
  padding: 4px;
  overflow-x: hidden;
  background-color: #131313;
  border: none;
  outline: none;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected {
    color: white !important;
    -webkit-text-fill-color: #fff !important;
  }
`;
const Underline = styled.div`
  background: ${(props) => props.theme.palette.primary};
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  transform-origin: left;
  transition: transform 500ms ease;
  transform: ${(props) => (props.focused ? "" : "scale3d(0, 1, 1)")};
`;
const Validation = styled.div`
  transition: all 300ms ease;
  color: ${(props) => props.theme.palette.error};
  opacity: 0;
  overflow: hidden;
  font-size: ${(props) => props.theme.font.size.m};
  margin-top: 4px;
  opacity: ${({ state }) => {
    switch (state) {
      case "entering":
      case "entered":
        return 1;
      default:
        return 0;
    }
  }};
  max-height: ${({ state }) => {
    switch (state) {
      case "entering":
      case "entered":
        return "20px";
      default:
        return "0px";
    }
  }};
`;

const EyeIcon = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
