import { API } from "utils";

export const getReport = async (query) => {
  try {
    const files = ["categories", "brands", "offers", "trademarks", "products"];

    Promise.all([
      API().get(`/admin/posts/${query}?download=categories.tsv`),
      API().get(`/admin/posts/${query}?download=brands.tsv`),
      API().get(`/admin/posts/${query}?download=offers.tsv`),
      API().get(`/admin/posts/${query}?download=trademarks.tsv`),
      API().get(`/admin/posts/${query}?download=products.tsv`),
    ]).then((res) => {
      res.forEach((response, index) => {
        let blob = new Blob([response.data], { type: "text/tsv" }),
          anchor = document.createElement("a");

        anchor.download = `${files[index]}.tsv`;
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        anchor.dataset.downloadurl = [
          "text/tsv",
          anchor.download,
          anchor.href,
        ].join(":");
        anchor.click();
      });
    });

    return { success: true, payload: "response" };
  } catch (e) {
    return { success: false, payload: e };
  }
};
