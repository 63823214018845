import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, AutoComplete } from "components";
import { Section, TagsPane, Tag } from "./../components";
import { useInput } from "hooks";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import RingLoader from "react-spinners/RingLoader";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation } from "@reach/router";
import { last } from "lodash";
import { getROIImage, removeROI, getTagNames } from "api";
import { css } from "@emotion/react";
import { FaTrash } from "react-icons/fa";

export const RegionOfInterest = ({
  data = [],
  loading,
  isUploading,
  onRemove = () => {},
  metaData = [],
}) => {
  console.log("roi data", data);
  // const searchTag = useInput();
  return (
    <Container>
      <Main title="SELECTED REGIONS">
        {loading ? (
          <SkeletonTheme color="#202020" highlightColor="#444">
            <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
            <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
            <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
            <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
            <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
          </SkeletonTheme>
        ) : (
          <div className="d-flex flex-column">
            {data &&
              data.map((item, index) => (
                <ROI
                  key={item.roi_hash}
                  hash={item.roi_hash}
                  index={index + 1}
                  onRemove={onRemove}
                />
              ))}
            {/* {isUploading && (
              <ThumbContainer className="d-flex flex-column m-2">
                <RingLoader
                  color="white"
                  size={80}
                  css={css`
                    margin: auto;
                  `}
                />
              </ThumbContainer>
            )} */}
          </div>
        )}
      </Main>
      <TagsSection title="ASSET TAGS">
        {/* <AutoComplete
          className="w-100"
          width={250}
          {...searchTag}
          placeholder="Search for Tags"
          func={(search) => {
            let results = [];
            metaData.forEach((data) => {
              results = [
                ...results,
                ...data.values.filter((a) =>
                  a.toLowerCase().includes(search.toLowerCase())
                ),
              ];
            });
            return { results };
          }}
        /> */}
        <div className="mt-3">
          {metaData.map((data, index) =>
            data.values.map((value, subIndex) => (
              <Tag
                value={value}
                className="w-100"
                key={`${index}-${subIndex}key`}
              />
            ))
          )}
        </div>
      </TagsSection>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
`;
const Main = styled(Section)`
  flex: 1;
`;
const TagsSection = styled(Section)`
  width: 300px;
`;

const ROI = ({ hash, index, onRemove = () => {} }) => {
  const location = useLocation();
  const tag = useInput("");
  const [isLoadingThumb, setIsLoadingThumb] = useState(false);
  const [thumbSrc, setThumbSrc] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isRemoving, setIsRemoving] = useState(false);

  const fetchThumb = async (params) => {
    setIsLoadingThumb(true);
    const { success, payload } = await getROIImage(
      last(location.pathname.split("/")),
      params
    );
    if (success) {
      setThumbSrc(payload);
    } else {
      setThumbSrc("");
    }
    setIsLoadingThumb(false);
  };

  useEffect(() => {
    if (hash) {
      fetchThumb(hash);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  const handleRemove = async () => {
    setIsRemoving(true);
    const res = await removeROI(last(location.pathname.split("/")), index);
    setIsRemoving(false);
    if (res.success) {
      onRemove(res.payload);
    }
  };

  const addTag = () => {};

  return (
    <ROIContainer>
      <ROIIndex className="d-flex flex-column mr-3">
        <div className="mx-auto">{index}</div>
        <div className="mt-auto cursor-pointer d-flex justify-content-center align-content-center">
          {isRemoving ? (
            <ClipLoader size={20} color="white" />
          ) : (
            <FaTrash color="white" size={20} onClick={handleRemove} />
          )}
        </div>
      </ROIIndex>
      <Thumb className="d-flex flex-column">
        {isLoadingThumb ? (
          <RingLoader
            color="white"
            size={80}
            css={css`
              margin: auto;
            `}
          />
        ) : (
          <img src={`data:image/jpeg;base64,${thumbSrc}`} alt="thumb" />
        )}
      </Thumb>
      <div className="flex-1 flex-column d-flex ml-3">
        <div className="color-secondary">ADD TAG</div>
        <div className="d-flex">
          <AutoComplete
            className="flex-1"
            width="100%"
            func={getTagNames}
            {...tag}
          />
          <Button
            size="small"
            buttonTheme="dark"
            width="80px"
            onClick={addTag}
            className="ml-2"
          >
            Add
          </Button>
          <Button
            size="small"
            buttonTheme="dark"
            width="80px"
            onClick={addTag}
            className="ml-2"
          >
            Clear
          </Button>
        </div>
        <TagsPane className="mt-2" />
      </div>
    </ROIContainer>
  );
};

const ROIIndex = styled.div`
  font-size: 20px;
  height: 120px;
`;

const ROIContainer = styled.div`
  display: flex;
  padding: 24px 8px 24px 8px;
  border-bottom: 1px solid ${(props) => props.theme.palette.itemsHover};
`;

const Thumb = styled.div`
  width: 120px;
  height: 120px;
  border: 1px solid #e2e2e2;
  box-sizing: content-box;
  img {
    width: 120px;
    height: 120px;
    object-fit: contain;
  }
`;
