import {
  INIT_UPLOAD,
  ADD_UPLOAD,
  REMOVE_UPLOAD,
  SET_UPLOAD_TITLE,
  SET_UPLOAD_STATE,
  SET_UPLOAD_OPEN,
  SET_UPLOAD_FILE_STATE,
  SET_UPLOADED_COUNT,
  INCREASE_UPLOADED_COUNT,
  CANCEL_UPLOAD,
  RETRY_UPLOAD,
  SET_UPLOAD_MINIMIZED,
  SET_UPLOAD_FILE_EXPAND,
  SYNC_REQUEST,
} from "./../types";
import { usePostContext } from "contexts";

export default function UploadReducer(state = {}, action) {
  const { payload } = action;
  const { addPost } = usePostContext();
  switch (action.type) {
    case INIT_UPLOAD:
      return payload;

    case ADD_UPLOAD:
      let newContent = [];
      console.log("add  uploads", payload);
      if (payload[0].parent !== undefined) {
        let old = [...state.content];
        let idx = old.findIndex((item) => item.id === payload[0].parent);
        console.log("file index", idx);
        newContent = [
          ...old.slice(0, idx + 1),
          ...payload.map((file, index) => {
            return {
              ...file,
              id: index + idx + 1,
            };
          }),
          ...old.slice(idx + 1).map((file, index) => {
            return {
              ...file,
              id: index + idx + payload.length + 1,
            };
          }),
        ];
        newContent[idx].expanded = true;
      } else {
        console.log("content", state?.content);
        newContent = [
          ...(state?.content ?? []),
          ...payload.map((file, index) => {
            return {
              ...file,
              id: (state ? state?.content?.length : 0) + index,
            };
          }),
        ];
      }
      const errorCount = payload.filter(
        ({ state }) => state === "typeError" || state === "sizeError"
      ).length;
      const newState = errorCount === 0 ? "uploading" : "error";
      const title =
        newState === "uploading"
          ? `Uploading ${
              newContent.filter(
                ({ state }) => state === "ready" || state === "uploading"
              ).length
            }  assets`
          : `${errorCount} uploads errored`;
      return {
        ...state,
        content: newContent,
        state: newState,
        minimized: false,
        title,
      };

    case REMOVE_UPLOAD:
      console.log("remove", payload);
      return {
        ...state,
        content: state.content.filter(({ id }) => id !== payload),
      };

    case SET_UPLOAD_TITLE:
      return {
        ...state,
        title: payload,
      };

    case SET_UPLOAD_STATE:
      return {
        ...state,
        state: payload,
      };

    case SET_UPLOAD_FILE_STATE:
      const {
        id,
        state: fileState,
        post_hash,
        title: postTitle,
        status,
        creation_time,
      } = payload;
      const { state: uploadingState } = state;
      return {
        ...state,
        content: state.content.map((file) => {
          if (file.id === id) {
            if (fileState === "uploaded" && uploadingState === "canceled") {
              return { ...file, state: "canceled" };
            } else {
              if (fileState === "uploaded") {
                addPost({ post_hash, title: postTitle, status, creation_time });
              }
              return {
                ...file,
                state: fileState,
                post_hash,
                title: postTitle,
                status,
                creation_time,
              };
            }
          } else {
            return file;
          }
        }),
      };

    case SET_UPLOAD_FILE_EXPAND:
      let newExpanded = [...state.content];
      let idx = newExpanded.findIndex((item) => item.id === payload);
      newExpanded[idx].expanded = newExpanded[idx].expanded ? false : true;
      return {
        ...state,
        content: newExpanded,
      };
    case SET_UPLOAD_OPEN:
      return {
        ...state,
        open: payload,
      };

    case SET_UPLOADED_COUNT:
      return {
        ...state,
        uploadedCount: payload,
      };
    case SET_UPLOAD_MINIMIZED:
      return {
        ...state,
        minimized: payload,
      };
    case INCREASE_UPLOADED_COUNT:
      return {
        ...state,
        uploadedCount: state.uploadedCount + 1,
      };
    case CANCEL_UPLOAD:
      const canceledCount = state.content.filter(
        (file) => file.state === "ready" || file.state === "uploading"
      ).length;
      return {
        ...state,
        content: state.content.map((file) => {
          if (file.state === "ready" || file.state === "uploading") {
            return { ...file, state: "canceled" };
          } else {
            return { ...file };
          }
        }),
        state: "canceled",
        title: `${canceledCount}  uploads canceled`,
        uploadedCount: 0,
      };
    case RETRY_UPLOAD:
      return {
        ...state,
        content: state.content.map((file) => {
          if (file.state === "canceled") {
            return { ...file, state: "ready" };
          } else {
            return { ...file };
          }
        }),
        state: "uploading",
      };

    case SYNC_REQUEST:
      return action.payload;
    default:
      return state;
  }
}
