import React from "react";

import styled from "styled-components";
import { EmptyHeader, Footer } from "components";
import { FaMeh } from "react-icons/fa";

export const ErrorPage = ({ error, resetErrorBoundary = () => {} }) => {
  return (
    <StyledLayout>
      <EmptyHeader />
      <Content>
        <div className="mx-auto my-auto d-flex">
          <div className="d-flex mr-4 mt-2">
            <FaMeh size={84} color="gray" />
          </div>
          <div>
            <h2>Sorry, the application has encountered an error.</h2>
            <div>
              Please retry and{" "}
              <span onClick={resetErrorBoundary}>
                <u>refresh the page</u>
              </span>
              .
            </div>
            <div>
              If problems persist, please{" "}
              <a
                href="https://deepad.atlassian.net/servicedesk/customer/portal/6"
                target="_blank"
                rel="noreferrer"
              >
                contact support
              </a>
              .
            </div>
            <details style={{ whiteSpace: "pre-wrap" }}>
              {error && error.toString()}
              <br />
            </details>
          </div>
        </div>
      </Content>
      <Footer />
    </StyledLayout>
  );
};

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: none;
  background: #0a0a0a;
`;

const Content = styled.div`
  width: 100vw;
  height: calc(100vh - 100px);
  color: white;
  display: flex;
  flex-direction: column;
  a {
    color: white;
    text-decoration: underline;
  }
`;
