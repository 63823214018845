import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Text, Desc } from "components";
import { BsFillCaretDownFill } from "react-icons/bs";

export const Accordion = ({ title, desc, children, className, open }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setContentHeight(contentRef.current.clientHeight + 32);
    return () => {};
  });
  useEffect(() => {
    if (open) {
      setIsOpen(true);
    }
    return () => {};
  }, [open]);
  return (
    <Container className={className}>
      <Main onClick={() => setIsOpen((state) => !state)}>
        <Text>{title}</Text>
        <Desc className="ml-2 mt-auto mb-1">{desc}</Desc>
        <ArrowIcon open={isOpen}>
          <BsFillCaretDownFill size={12} />
        </ArrowIcon>
      </Main>
      <Content open={isOpen} maxHeight={contentHeight}>
        <div className="m-3" ref={contentRef}>
          {children}
        </div>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin: 12px 0;
`;

const Main = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.gray};
  display: flex;
  cursor: pointer;
  transition: all 300ms ease;
  padding-bottom: 2px;
  &:hover {
    opacity: 0.8;
  }
`;

const ArrowIcon = styled.div`
  transition: all 300ms ease;
  margin-left: auto;
  transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0deg)")};
  color: white;
`;
const Content = styled.div`
  overflow: hidden;
  transition: all 300ms ease;
  max-height: ${(props) => (props.open ? `${props.maxHeight}px` : 0)};
`;
