import React, { useState } from "react";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
export const SearchInput = ({
  className,
  onBlur = () => {},
  value,
  onChange = () => {},
  onEnter = () => {},
  disabled = false,
  ...props
}) => {
  const [focused, setFocused] = useState(false);

  const handleBlur = (event) => {
    setFocused(false);
    onBlur(event);
  };

  const clear = () => {
    onChange("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onEnter(value);
    }
  };

  return (
    <Container className={className}>
      <div className="d-flex position-relative">
        <StyledInput
          aria-labelledby={"search"}
          onFocus={() => setFocused(true)}
          onBlur={handleBlur}
          aria-describedby={"search"}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          {...props}
          onKeyPress={(e) => handleKeyPress(e)}
        />
        {value && (
          <CloseIcon onClick={clear}>
            <AiOutlineClose size={20} />
          </CloseIcon>
        )}
        <Underline focused={focused} />
      </div>
    </Container>
  );
};
const CloseIcon = styled.div`
  color: white;
  position: absolute;
  right: 0;
  top: 6px;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.7;
  }
`;
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 4px 0 4px 0;
`;

const StyledInput = styled.input`
  display: block;
  color: white;
  box-shadow: inset 0 -2px 0 0 ${(props) => props.theme.palette.gray};
  transition: background-color 5000s linear 0s;
  width: 100%;
  font-size: ${(props) => props.theme.font.size.m};
  line-height: ${(props) => props.theme.font.size.xm};
  height: 40px;
  padding: 4px;
  overflow-x: hidden;
  background-color: transparent;
  border: none;
  &:-internal-autofill-selected {
    -webkit-text-fill-color: var(--colorTextBody);
    box-shadow: 0 0 0px 1000px rgb(var(--rgbText) / 0.1) inset;
  }

  /* Needs to be a single selector to work in safari */
  &:-webkit-autofill {
    -webkit-text-fill-color: var(--colorTextBody);
    box-shadow: 0 0 0px 1000px rgb(var(--rgbText) / 0.1) inset;
  }

  outline: none;
  &:focus {
    outline: none;
  }

  &::-webkit-contacts-auto-fill-button:hover {
    background-color: rgb(var(--rgbPrimary));
  }
`;
const Underline = styled.div`
  background: ${(props) => props.theme.palette.primary};
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  transform-origin: left;
  transition: transform 500ms ease;
  transform: ${(props) => (props.focused ? "" : "scale3d(0, 1, 1)")};
`;
