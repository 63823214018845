import React from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { LoginLayout } from "layouts";
import { Button, FormInput, Link } from "components";
import LogoImg from "assets/img/logo.png";
import { navigate } from "@reach/router";

const LoginForm = styled.div`
  width: 428px;
  margin: 60px auto 0 auto;
`;
const Logo = styled.img`
  width: 600px;
  margin: auto auto 0 auto;
`;

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Please enter your email"),
  confirm: Yup.string().required("Please enter your email"),
});

export const ResetPage = () => {
  return (
    <LoginLayout>
      <Logo src={LogoImg} alt="logo" />
      <LoginForm>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            navigate("/");
          }}
        >
          {() => (
            <Form>
              <FormInput label="New Password" name="password" />
              <FormInput label="Confirm Password" name="confirm" />
              <Button size="large" className="mt-3">
                Submit
              </Button>
            </Form>
          )}
        </Formik>
        <div className="d-flex mt-3">
          <Link className="ml-auto" href="/">
            Login here
          </Link>
        </div>
      </LoginForm>
    </LoginLayout>
  );
};
