import React from "react";
import styled from "styled-components";

export const Tag = ({ type, className }) => {
  return (
    <Container className={className} type={type}>
      {type}
    </Container>
  );
};

const Container = styled.div`
  text-transform: uppercase;
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  color: white;
`;
