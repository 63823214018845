export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const SET_USER_MODE = "SET_USER_MODE";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_APP_INFO = "GET_APP_INFO";

export const SYNC_REQUEST = "SYNC_REQUEST";

export const QUERY_POSTS = "QUERY_POSTS";
export const QUERY_INIT = "QUERY_INIT";
export const SET_QUERY = "SET_QUERY";
export const SET_QUERY_PAGE = "SET_QUERY_PAGE";

export const GET_POST = "GET_POST";
export const INIT_POST = "INIT_POST";
export const ADD_POST = "ADD_POST";
export const POST_HOME = "POST_HOME";
export const REMOVE_POST = "REMOVE_POST";
export const DELETE_POST = "DELETE_POST";
export const SET_LOADING = "SET_LOADING";
export const SET_POST_FETCHING = "SET_POST_FETCHING";
export const FETCH_MORE_POSTS = "FETCH_MORE_POSTS";
export const SET_ERROR = "SET_ERROR";
export const SET_CURRENT_POST = "SET_CURRENT_POST";

export const SET_QUERY_UPLOAD_DATES = "SET_QUERY_UPLOAD_DATES";
export const SET_QUERY_CREATION_DATES = "SET_QUERY_CREATION_DATES";
export const SET_QUERY_TAGS = "SET_QUERY_TAGS";
export const SET_QUERY_SEARCH = "SET_QUERY_SEARCH";
export const SET_QUERY_DURATION = "SET_QUERY_DURATION";
export const REPLACE_QUERY_TAGS = "REPLACE_QUERY_TAGS";

export const INIT_UPLOAD = "INIT_UPLOAD";
export const ADD_UPLOAD = "ADD_UPLOAD";
export const REMOVE_UPLOAD = "REMOVE_UPLOAD";
export const SET_UPLOAD_TITLE = "SET_UPLOAD_TITLE";
export const SET_UPLOADED_COUNT = "SET_UPLOADED_COUNT";
export const INCREASE_UPLOADED_COUNT = "INCREASE_UPLOADED_COUNT";
export const SET_UPLOAD_STATE = "SET_UPLOAD_STATE";
export const SET_UPLOAD_FILE_STATE = "SET_UPLOAD_FILE_STATE";
export const SET_UPLOAD_OPEN = "SET_UPLOAD_OPEN";
export const SET_UPLOAD_MINIMIZED = "SET_UPLOAD_MINIMIZED";
export const CANCEL_UPLOAD = "CANCEL_UPLOAD";
export const RETRY_UPLOAD = "RETRY_UPLOAD";
export const SET_UPLOAD_FILE_EXPAND = "SET_UPLOAD_FILE_EXPAND";

export const SET_POST_TOOLBAR_STATE = "SET_POST_TOOLBAR_STATE";
