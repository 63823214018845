import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "components";
import { useLocation, useNavigate } from "@reach/router";
import { useAuthContext, usePostContext } from "contexts";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { last } from "lodash";

export const Toolbar = ({ isLoading, title, onDebug, onThumbnail }) => {
  const { userInfo } = useAuthContext();
  const { posts } = usePostContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [prevHash, setPrevHash] = useState("");
  const [nextHash, setNextHash] = useState("");

  useEffect(() => {
    if (location.pathname.split("/")[1] === "post") {
      const currentHash = last(location.pathname.split("/"));
      setPrevHash("");
      setNextHash("");
      if (posts && posts.length > 0) {
        const currentIndex = posts.findIndex(
          (d) => d.post_hash === currentHash
        );
        if (currentIndex !== -1) {
          if (currentIndex === posts.length - 1 && posts.length > 1) {
            setNextHash("");
            setPrevHash(posts[currentIndex - 1].post_hash);
          } else if (currentIndex === 0 && posts.length > 1) {
            setPrevHash("");
            setNextHash(posts[currentIndex + 1].post_hash);
          } else if (posts.length > 2) {
            setPrevHash(posts[currentIndex - 1].post_hash);
            setNextHash(posts[currentIndex + 1].post_hash);
          }
        }
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleNext = () => {
    navigate(`/post/${nextHash}`, { replace: true, state: { hash: nextHash } });
  };

  const handlePrev = () => {
    navigate(`/post/${prevHash}`, { replace: true, state: { hash: prevHash } });
  };

  const handleThumbnail = () => {
    onThumbnail();
  };

  const handleDebug = () => {
    onDebug();
  };

  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container>
        <div className="my-auto">
          <Skeleton height={24} width={120} />
        </div>
        <div className="ml-auto d-flex">
          {userInfo.rank === "adm" && (
            <div className="my-auto ml-2 d-flex">
              <Button
                buttonTheme="dark"
                size="small"
                disabled
                className="my-auto"
              >
                Thumbnail
              </Button>
            </div>
          )}
          {userInfo.rank === "adm" && (
            <div className="my-auto ml-2 d-flex">
              <Button
                buttonTheme="dark"
                size="small"
                disabled
                className="my-auto"
              >
                Debug
              </Button>
            </div>
          )}
          {userInfo.rank === "adm" && (
            <div className="my-auto ml-2 d-flex">
              <Button
                buttonTheme="dark"
                size="small"
                disabled
                className="my-auto"
              >
                Additional Information
              </Button>
            </div>
          )}
          {userInfo.rank === "adm" && (
            <div className="my-auto ml-2 d-flex">
              <Button
                buttonTheme="dark"
                size="small"
                width="90px"
                disabled
                className="my-auto"
              >
                Analyze
              </Button>
            </div>
          )}
          <div className="my-auto ml-2 d-flex">
            <Button
              buttonTheme="dark"
              size="small"
              width="90px"
              disabled
              className="my-auto"
            >
              Previous
            </Button>
          </div>
          {userInfo.rank === "adm" && (
            <div className="my-auto ml-2 d-flex">
              <Button
                buttonTheme="dark"
                size="small"
                width="90px"
                className="my-auto"
                disabled
              >
                Random
              </Button>
            </div>
          )}
          <Button
            buttonTheme="dark"
            size="small"
            width="90px"
            className="my-auto ml-2"
            disabled
          >
            Next
          </Button>
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    <Container>
      <span className="my-auto">{title}</span>
      <div className="ml-auto d-flex">
        {userInfo.rank === "adm" && (
          <div className="my-auto ml-2 d-flex">
            <Button
              buttonTheme="dark"
              size="small"
              onClick={handleThumbnail}
              className="my-auto"
            >
              Thumbnail
            </Button>
          </div>
        )}
        {userInfo.rank === "adm" && (
          <div className="my-auto ml-2 d-flex">
            <Button
              buttonTheme="dark"
              size="small"
              onClick={handleDebug}
              className="my-auto"
            >
              Debug
            </Button>
          </div>
        )}
        {userInfo.rank === "adm" && (
          <div className="my-auto ml-2 d-flex">
            <Button buttonTheme="dark" size="small" className="my-auto">
              Additional Information
            </Button>
          </div>
        )}
        {userInfo.rank === "adm" && (
          <div className="my-auto ml-2 d-flex">
            <Button
              buttonTheme="dark"
              size="small"
              width="90px"
              className="my-auto"
            >
              Analyze
            </Button>
          </div>
        )}

        <div className="my-auto ml-2 d-flex">
          <Button
            buttonTheme="dark"
            size="small"
            width="90px"
            className="my-auto"
            disabled={!prevHash}
            onClick={handlePrev}
          >
            Previous
          </Button>
        </div>
        {userInfo.rank === "adm" && (
          <div className="my-auto ml-2 d-flex">
            <Button
              buttonTheme="dark"
              size="small"
              width="90px"
              className="my-auto"
            >
              Random
            </Button>
          </div>
        )}
        <Button
          buttonTheme="dark"
          size="small"
          width="90px"
          className="my-auto ml-2"
          disabled={!nextHash}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </Container>
  );
};
const Container = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  border-top: 4px solid ${(props) => props.theme.palette.background};
  border-bottom: 2px solid ${(props) => props.theme.palette.background};
  color: ${(props) => props.theme.palette.white};
  font-size: ${(props) => props.theme.font.size.l};
  display: flex;
  height: 60px;
  padding: 0 24px;
`;
