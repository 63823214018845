import styled from "styled-components";

export const Box = styled.div`
  margin: 2px;
  padding: 16px 16px;
  color: ${(props) => props.theme.palette.white};
  background: ${(props) => props.theme.palette.backgrounds};
  font-size: ${(props) => props.theme.font.size.sm};
  border-radius: 2px;
  .title {
    font-size: ${(props) => props.theme.font.size.xm};
    line-height: ${(props) => props.theme.font.size.xm};
  }
`;
