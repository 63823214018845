/* eslint-disable no-unused-vars */
import { createContext, useContext, useState, useEffect } from "react";
import { useLocalstorageState } from "rooks";
import { getAdvertisers, getSubCategories } from "api";

const DataContext = createContext();
export const useDataContext = () => useContext(DataContext);
const STORAGE_KEY = "data-state";

export const DataProvider = ({ children }) => {
  const initialState = {
    advertisers: [],
    subCategories: [],
  };
  const [value, set] = useLocalstorageState(STORAGE_KEY, initialState);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    set(state);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const setAdvertisers = (advertisers = []) => {
    setState({ ...state, advertisers });
  };

  const setSubCategories = (subCategories = []) => {
    setState({ ...state, subCategories });
  };

  const initData = async () => {
    let res = await getAdvertisers();
    if (res.success) {
      setAdvertisers(res.results);
    } else {
      setAdvertisers([]);
    }
    res = await getSubCategories();
    if (res.success) {
      setSubCategories(res.results);
    } else {
      setSubCategories([]);
    }
  };

  return (
    <DataContext.Provider
      value={{
        ...state,
        setAdvertisers,
        setSubCategories,
        initData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
