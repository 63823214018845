import { last } from "lodash";
const urlRegex = /(https?:\/\/[^\s]+)/g;

export const parseXLS = async (f) => {};

export const parseCSV = async (data) => {
  const csvContent = await new Response(data).text();
  let urls = csvContent.match(urlRegex).map((url) => {
    if (last(url) === ",") {
      return url.slice(0, url.length - 1);
    }
    return url;
  });
  return urls;
};
