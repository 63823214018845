import { API } from "utils";

export const roiApi = async (id, { tl, br, frame }) => {
  try {
    const formData = new FormData();
    formData.append("tl", tl);
    formData.append("br", br);
    formData.append("frame", frame);
    const response = await API().post(`api/roi/${id}`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [] };
  }
};

export const getROIImage = async (id, param) => {
  try {
    const response = await API().get(`admin/roi/${id}-${param}.png`, {
      responseType: "arraybuffer",
    });
    return {
      success: true,
      payload: Buffer.from(response.data, "binary").toString("base64"),
    };
  } catch (e) {
    return { success: false, payload: "", error: e };
  }
};

export const removeROI = async (id, frame) => {
  const formData = new FormData();
  formData.append("frame", frame);
  try {
    const response = await API().post(`api/roi/delete/${id}`, formData);
    return {
      success: response.data.success,
      payload: response.data.data,
    };
  } catch (e) {
    return { success: false, payload: "", error: e };
  }
};

export const getAllROI = async (id) => {
  try {
    const response = await API().get(`api/roi/${id}`);
    return {
      success: response.data.success,
      payload: response.data.data,
    };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
