import React from "react";
import Slider from "rc-slider";
import "rc-tooltip/assets/bootstrap.css";
import raf from "rc-util/lib/raf";
import Tooltip from "rc-tooltip";
import { convertString2Time } from "utils/time";
import styled from "styled-components";
import "./style.scss";

const HandleTooltip = (props) => {
  const {
    value,
    children,
    visible,
    tipFormatter = (val) => convertString2Time(val),
    ...restProps
  } = props;

  const tooltipRef = React.useRef();
  const rafRef = React.useRef(null);

  function cancelKeepAlign() {
    raf.cancel(rafRef.current);
  }

  function keepAlign() {
    rafRef.current = raf(() => {
      tooltipRef.current?.forcePopupAlign();
    });
  }

  React.useEffect(() => {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }

    return cancelKeepAlign;
  }, [value, visible]);

  return (
    <Tooltip
      placement="top"
      overlay={tipFormatter(value)}
      overlayInnerStyle={{ minHeight: "auto" }}
      ref={tooltipRef}
      visible={visible}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

const handleRender = (node, props) => {
  return (
    <HandleTooltip value={props.value} visible={props.dragging}>
      {node}
    </HandleTooltip>
  );
};
export const PlayerSeekbar = ({
  className,
  duration = 0,
  value,
  onChange = () => {},
}) => {
  return (
    <Container className={className}>
      <Slider
        min={0}
        max={duration}
        defaultValue={0}
        onChange={onChange}
        value={value}
        handleRender={handleRender}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
`;
