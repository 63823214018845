import React from "react";
import styled from "styled-components";
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import PuffLoader from "react-spinners/PuffLoader";
import { css } from "@emotion/react";

export const Stats = ({ data, isLoading }) => {
  return isLoading ? (
    <Container>
      <PuffLoader
        color="rgb(255, 255, 112)"
        size={180}
        css={css`
          margin: auto;
        `}
      />
    </Container>
  ) : (
    <Container>
      <FeaturesContainer>
        {data.map((d, index) => (
          <Feature key={`${index}key`} {...d} />
        ))}
      </FeaturesContainer>
      <div className="ml-auto mt-auto">
        <PieChart width={400} height={400}>
          <Pie
            data={data}
            cx={220}
            cy={220}
            innerRadius={120}
            outerRadius={170}
            fill="#8884d8"
            paddingAngle={2}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip
            formatter={(value, name) => {
              return `${(value * 100).toFixed(1)}%`;
            }}
          />
        </PieChart>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-top: 40px;
  position: relative;
  /* height: 100%; */
  flex: 1;
`;
const FeaturesContainer = styled.div`
  position: absolute;
`;
const Feature = ({ name, value, color }) => (
  <FeatureContainer>
    <FeatureColor color={color} />
    <span>{`${name} - ${(value * 100).toFixed(1)}%`}</span>
  </FeatureContainer>
);
const FeatureContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  color: white;
  font-size: 20px;
  line-height: 20px;
`;
const FeatureColor = styled.div`
  border: 1px solid ${(props) => props.theme.palette.secondary};
  width: 20px;
  height: 20px;
  background: ${(props) => props.color};
  margin-right: 16px;
`;
