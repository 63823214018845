import React from "react";
import theme from "theme";
import { ThemeProvider } from "styled-components";
import { Router } from "@reach/router";
import {
  LoginPage,
  HomePage,
  DetailPage,
  RegisterPage,
  ResetPage,
  ForgotPage,
  NotFoundPage,
} from "pages";
import { PrivateRoute, PublicRoute, ErrorPage } from "components";
import {
  AuthProvider,
  PostProvider,
  UploadProvider,
  DataProvider,
} from "contexts";
import { CookiesProvider } from "react-cookie";
import ReactTooltip from "react-tooltip";
import { ErrorBoundary } from "react-error-boundary";

import "theme/all.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <ErrorPage
        role="alert"
        error={error}
        resetErrorBoundary={resetErrorBoundary}
      />
    );
  }

  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <CookiesProvider>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <PostProvider>
                <UploadProvider>
                  <DataProvider>
                    <Router>
                      <PublicRoute as={LoginPage} path="/" />
                      <PublicRoute as={NotFoundPage} path="/404" />
                      <PublicRoute as={LoginPage} path="/login" />
                      <PublicRoute as={RegisterPage} path="/register" />

                      <PublicRoute as={ResetPage} path="/reset-password" />
                      <PublicRoute as={ForgotPage} path="/forgot-password" />
                      <PrivateRoute as={HomePage} path="/home" />
                      <PrivateRoute as={DetailPage} path="/post/:id" />
                    </Router>
                    <ReactTooltip
                      id="player-tools"
                      multiline
                      place="bottom"
                      effect="solid"
                      className="player-tools"
                    />
                  </DataProvider>
                </UploadProvider>
              </PostProvider>
            </AuthProvider>
          </ThemeProvider>
        </CookiesProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
