import React, { useState } from "react";
import classNames from "classnames";
import Calendar from "react-calendar";
import { format, parse, isValid } from "date-fns";
import useOnclickOutside from "react-cool-onclickoutside";
import "react-calendar/dist/Calendar.css";
import { FaCalendarAlt } from "react-icons/fa";
import styled from "styled-components";
import "./style.scss";

export const DatePicker = ({
  value,
  className,
  label,
  type = "text",
  onChange = () => {},
  placeholder,
}) => {
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const ref = useOnclickOutside(() => {
    setIsCalendarVisible(false);
  });

  const handleChange = (param) => {
    onChange(param.target.value);
  };

  return (
    <div className={classNames("date-picker-control", className)} ref={ref}>
      <Label>{label}</Label>
      <div className="d-flex position-relative">
        <input
          className="date-picker-control__input"
          onChange={(e) => handleChange(e)}
          value={value}
          placeholder={placeholder}
          type={type}
          onFocus={() => setIsCalendarVisible(true)}
        />
        <div className="date-picker-control__icon">
          <FaCalendarAlt color="white" />
        </div>
      </div>
      <div
        className={classNames("date-picker-control__calendar", {
          visible: isCalendarVisible,
        })}
      >
        <Calendar
          onChange={(e) => {
            onChange(format(new Date(e), "MM/dd/yyyy"));
            setIsCalendarVisible(false);
          }}
          formatLongDate={(locale, date) =>
            format(new Date(date), "MM/dd/yyyy")
          }
          value={
            value &&
            value !== "Invalid date" &&
            isValid(parse(value, "MM/dd/yyyy", new Date()))
              ? parse(value, "MM/dd/yyyy", new Date())
              : new Date()
          }
          // activeStartDate={
          //   value &&
          //   value !== "Invalid date" &&
          //   isValid(parse(value, "MM/dd/yyyy", new Date()))
          //     ? parse(value, "MM/dd/yyyy", new Date())
          //     : new Date()
          // }
        />
      </div>
    </div>
  );
};

const Label = styled.div`
  color: white;
  font-size: 12px;
  line-height: 12px;
`;
