import React, { useState, useEffect } from "react";
import InputRange from "react-input-range";
import { Button } from "components";
import { DURATION_MIN, DURATION_MAX } from "config";
import "react-input-range/lib/css/index.css";

export const Duration = ({
  min = DURATION_MIN,
  max = DURATION_MAX,
  value,
  onChange,
}) => {
  const [duration, setDuration] = useState({ min, max });

  useEffect(() => {
    setDuration(value);
    return () => {};
  }, [value]);

  const handleSelect = () => {
    onChange(duration);
  };
  const handleClear = () => {
    setDuration({ min, max });
    onChange({ min, max });
  };

  return (
    <div>
      <InputRange
        // formatLabel={(value) => `${value} min`}
        maxValue={DURATION_MAX}
        minValue={DURATION_MIN}
        value={duration}
        onChange={(value) => setDuration(value)}
      />
      <div className="d-flex mt-4 justify-content-between">
        <Button size="small" width="110px" onClick={handleSelect}>
          Set Duration
        </Button>
        <Button size="small" width="100px" onClick={handleClear}>
          Clear
        </Button>
      </div>
    </div>
  );
};
