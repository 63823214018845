import React from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const TextAudio = ({ isLoading, data = "Silence" }) => {
  return isLoading ? (
    <Container>
      <Header>AUDIO</Header>
      <div className="mt-2">
        <SkeletonTheme color="#202020" highlightColor="#444">
          <Skeleton count={2} />
        </SkeletonTheme>
      </div>
    </Container>
  ) : (
    <Container>
      <Header>AUDIO</Header>
      <div className="d-flex mt-2">
        <Text>{data}</Text>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 20px;
`;

const Header = styled.div`
  font-size: 16px;
  color: white;
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
`;

const Text = styled.div`
  font-size: 14px;
  color: white;
  word-break: break-word;
`;
