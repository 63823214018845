/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import styled from "styled-components";
import { PinFieldModal } from "modals";
import { useModal } from "hooks";
import { usePostContext } from "contexts";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  Section,
  SettingMenu,
  SummaryItem,
  SummaryItem2,
} from "./../components";
export const SummarySection = ({
  children,
  post,
  isLoading,
  summary,
  metaData,
  onUpdate = () => {},
  onSetValue = () => {},
}) => {
  const pinModal = useModal();
  const { fieldPinData } = usePostContext();

  const handleSettingsClick = (params) => {
    switch (params) {
      case "pin":
        pinModal.openModal();
        break;

      default:
        break;
    }
  };

  return isLoading ? (
    <Section title="Summary">
      <SkeletonTheme color="#202020" highlightColor="#444">
        <Skeleton count={3} />
      </SkeletonTheme>
    </Section>
  ) : (
    <Wrapper>
      <Header>
        <span className="my-auto">Summary</span>
        <SettingMenu className="ml-auto" onClick={handleSettingsClick} />
      </Header>
      <Content>
        <Title>{post?.title}</Title>
        <div className="mb-1">{summary}</div>
        <Container>
          {metaData
            .filter((row) => row?.values?.length > 0 || row?.editable)
            .map((item, index) => (
              <SummaryItem
                key={`${index}key`}
                {...item}
                pin={fieldPinData.includes(item.id)}
                editable={item?.editable}
                certId={post?.cert?.cert_id}
                onUpdate={onUpdate}
                onSetValue={onSetValue}
              />
            ))}
          {post?.data?.pre?.label && (
            <SummaryItem2
              label="Label"
              values={[post?.data?.pre?.label]}
              link
            />
          )}
          {post?.data?.pre?.hint && (
            <SummaryItem2 label="Banner" values={[post.data.pre.hint]} />
          )}
          {post?.data?.pre?.whisper && (
            <SummaryItem2 label="Snippet" values={[post.data.pre.whisper]} />
          )}
          {post?.source && post?.source.startsWith("http") && (
            <SummaryItem2 label="Source" values={[post?.source]} link />
          )}
          {post?.data?.pre?.landing && (
            <SummaryItem2
              label="Landing"
              values={[post.data.pre.landing]}
              link
            />
          )}

          {post?.data?.pre?.impressions && (
            <SummaryItem2
              label="Impressions"
              values={[post.data.pre.impressions]}
            />
          )}
          {post?.data?.pre?.spend_est && (
            <SummaryItem2 label="Spend" values={[post.data.pre.spend_est]} />
          )}
          {post?.cert?.meta?.faces?.genders &&
          post?.cert?.meta?.faces?.genders.length > 0 ? (
            <SummaryItem2
              label="People"
              values={[post.cert.meta.faces.genders]}
            />
          ) : post?.cert?.meta?.faces?.ages?.low &&
            post?.cert?.meta?.faces?.ages?.high ? (
            <SummaryItem2
              label="People"
              values={[
                `Age: ${post.cert.meta.faces.ages.low} - ${post.cert.meta.faces.ages.high}`,
              ]}
            />
          ) : null}
        </Container>
      </Content>
      <PinFieldModal {...pinModal} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 2px;
  padding: 16px;
  background-color: ${(props) => props.theme.palette.backgrounds};
`;
const Header = styled.div`
  display: flex;
  color: ${(props) => props.theme.palette.secondary};
  text-transform: uppercase;
  font-size: 14px;
`;
const Content = styled.div`
  padding: 8px;
  color: white;
  font-size: 14px;
`;

const Title = styled.div`
  font-size: 36px;
  color: white;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  a {
    color: white;
    transition: all 300ms ease;
    text-decoration: none;
    &:hover {
      color: ${(props) => props.theme.palette.primary};
    }
  }
`;
