import React from "react";
import styled from "styled-components";

export const Event = ({ index, name, type, frame = "", onClick }) => (
  <EventContainer onClick={onClick}>
    <EventNumber>{index}</EventNumber>
    <div className="d-flex flex-column">
      <EventName className="mt-auto">{name}</EventName>
      <EventCategory className="mb-auto">{type}</EventCategory>
    </div>
    {frame !== "" && (
      <div className="my-auto ml-1">{`${parseInt(frame / 30)}s`}</div>
    )}
  </EventContainer>
);
const EventContainer = styled.div`
  display: flex;
  background: ${(props) => props.theme.palette.items};
  position: relative;
  height: 42px;
  margin-bottom: 12px;
  margin-left: 20px;
  padding-left: 30px;
  padding-right: 12px;
  justify-content: space-between;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    box-shadow: 1px 2px 2px #0a0a0a;
    background: ${(props) => props.theme.palette.itemsHover};
  }
  &::after {
    content: " ";
    position: absolute;
    height: 12px;
    width: 2px;
    background: #707070;
    bottom: -12px;
    left: 0px;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
`;

const EventNumber = styled.div`
  background: #ffdd3a;
  border: 2px solid ${(props) => props.theme.palette.secondary};
  color: black;
  position: absolute;
  width: 42px;
  height: 42px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 18px;
  text-align: center;
  left: -20px;
`;
const EventName = styled.div`
  color: white;
  font-size: 16px;
  line-height: 16px;
`;
const EventCategory = styled.div`
  font-size: 12px;
  line-height: 12px;
  margin-top: 4px;
  color: ${(props) => props.theme.palette.secondary};
`;
