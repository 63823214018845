import React, { useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { Transition } from "react-transition-group";
import { ImSpinner9 } from "react-icons/im";
import { ScrollView } from "components";
import { useUploadContext, usePostContext } from "contexts";
import { navigate } from "@reach/router";
import { FaCaretUp } from "react-icons/fa";

// import { RiUploadCloudFill } from "react-icons/ri";
import { MdClose } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import {
  UploadCloseModal,
  UploadFailedModal,
  UploadInvalidFormatModal,
  UploadInvalidSizeModal,
  UploadDuplicateModal,
  UploadParseFailedModal,
  UploadBadUrlModal,
} from "modals";
import { useModal } from "hooks";
import { deletePostApi } from "api";
import { FileItem } from "./File";
import { ExpandableFileContent } from "./ExpandableFileContent";

export const ProcessModal = ({
  open,
  type = "processing",
  onClose,
  children,
  files = [],
  urls = [],
  onUploadFinish = () => {},
}) => {
  const closeModal = useModal();
  const failedModal = useModal();
  const invalidFormatModal = useModal();
  const invalidSizeModal = useModal();
  const duplicateModal = useModal();
  const badUrlModal = useModal();
  const parseFailedModal = useModal();
  const {
    content,
    initUploads,
    removeUploads,
    state,
    closeUpload,
    title,
    cancelUpload,
    retryUpload,
    minimized,
    setUploadMinimized,
    setUploadFileExpand,
  } = useUploadContext();

  const { deletePost } = usePostContext();

  const [selectedFile, setSelectedFile] = useState({});

  useEffect(() => {
    if (open) {
      setUploadMinimized(false);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleMinimized = () => {
    setUploadMinimized(!minimized);
  };
  const clickClose = () => {
    if (state === "uploading") {
      closeModal.openModal();
    } else {
      initUploads();
      closeUpload();
    }
  };

  const handleClose = () => {
    initUploads();
    closeUpload();
    closeModal.onClose();
  };

  const handleFileClick = (file) => {
    const { state, post_hash, category, id } = file;
    setSelectedFile(file);
    if (category === "file") {
      switch (state) {
        case "parseFailed":
          parseFailedModal.openModal();
          break;
        default:
          setUploadFileExpand(id);
          break;
      }
    } else {
      switch (state) {
        case "typeError":
          invalidFormatModal.openModal();
          break;
        case "sizeError":
          invalidSizeModal.openModal();
          break;
        case "duplicateError":
          navigate(`/post/${post_hash}`);
          break;
        case "failed":
          if (category === "media" || category === "url") {
            failedModal.openModal();
          }
          break;
        case "uploaded":
          navigate(`/post/${post_hash}`);
          break;
        case "badUrl":
          badUrlModal.openModal();
          break;
        default:
          break;
      }
    }
  };

  const handleRemoveUpload = (file) => {
    const { id } = file;
    console.log("remove upload", file);
    removeUploads(id);
  };

  const handleRemoveAsset = async (file) => {
    handleRemoveUpload(file);
    const { post_hash } = file;
    await deletePostApi(post_hash);
    deletePost(post_hash);
  };

  const handleRetryUpload = (file) => {};

  const handleCancel = () => {
    if (state === "uploading") {
      cancelUpload();
    } else if (state === "canceled") {
      retryUpload();
    }
  };

  return (
    <Transition in={open} timeout={300}>
      {(params) => (
        <Container isOpen={open} tabIndex={-1} state={state}>
          <Header type={state} state={state}>
            <span className="my-auto">{title}</span>
            {state === "uploading" && <Loader />}
            <MinimizeIcon
              className="d-flex ml-auto"
              minimized={minimized}
              onClick={handleMinimized}
            >
              <FaCaretUp color="white" size={28} className="m-auto" />
            </MinimizeIcon>
            <IconButton className="ml-2 my-auto" onClick={clickClose}>
              <MdClose color="white" size={28} className="m-auto" />
            </IconButton>
          </Header>
          <Content minimized={minimized}>
            <div className="d-flex mt-2">
              {(state === "uploading" || state === "canceled") && (
                <CancelButton className="ml-auto mr-3" onClick={handleCancel}>
                  {state === "uploading" ? "Cancel" : "Retry"}
                </CancelButton>
              )}
            </div>
            <ScrollView className="mb-2 mt-2">
              {content &&
                content.length > 0 &&
                content.map((file, index) => {
                  const { parent, id, expanded } = file;
                  return parent === undefined ? (
                    <div key={`${index}key`}>
                      <FileItem
                        file={file}
                        key={`${index}key`}
                        onClick={handleFileClick}
                        onRemove={handleRemoveUpload}
                        expanded={
                          content.filter((row) => row.parent === id).length >
                            0 && expanded
                            ? "expanded"
                            : content.filter((row) => row.parent === id)
                                .length > 0 && !expanded
                            ? "collapsed"
                            : ""
                        }
                      />
                      {content.filter((row) => row.parent === id).length >
                        0 && (
                        <ExpandableFileContent open={expanded}>
                          {content
                            .filter((row) => row.parent === id)
                            .map((row, index) => (
                              <FileItem
                                key={`key${index}`}
                                file={row}
                                onClick={handleFileClick}
                                onRemove={handleRemoveUpload}
                                expanded=""
                              />
                            ))}
                        </ExpandableFileContent>
                      )}
                    </div>
                  ) : null;
                })}
            </ScrollView>
          </Content>
          <UploadCloseModal {...closeModal} onCancel={handleClose} />
          <UploadFailedModal
            {...failedModal}
            onRemove={handleRemoveUpload}
            onRetry={handleRetryUpload}
            file={selectedFile}
          />
          <UploadInvalidFormatModal
            {...invalidFormatModal}
            onRemove={handleRemoveUpload}
            file={selectedFile}
          />
          <UploadInvalidSizeModal
            {...invalidSizeModal}
            onRemove={handleRemoveUpload}
            file={selectedFile}
          />
          <UploadDuplicateModal
            {...duplicateModal}
            file={selectedFile}
            onRemove={handleRemoveAsset}
          />
          <UploadBadUrlModal
            {...badUrlModal}
            file={selectedFile}
            onRemove={handleRemoveAsset}
          />
          <UploadParseFailedModal
            {...parseFailedModal}
            file={selectedFile}
            onRemove={handleRemoveAsset}
          />
          <ReactTooltip
            id="upload-tooltip"
            className="player-tools"
            place="bottom"
            multiline
            effect="solid"
            brand-tooltip="#000"
          />
        </Container>
      )}
    </Transition>
  );
};
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const Loader = styled(ImSpinner9)`
  vertical-align: middle;
  margin: auto auto auto 12px;
  animation: ${css`
    ${rotate} 1s linear infinite
  `};
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  color: ${(props) => props.theme.palette.white};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-size: ${(props) => props.theme.font.size.xm};
  width: 600px;
  /* height: 240px; */
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  overflow: auto;
  position: fixed;
  right: 25px;
  bottom: 0px;
  border: ${(props) =>
    `1px solid ${
      props.state === "error"
        ? props.theme.palette.error
        : props.theme.palette.primaryOutline
    }`};
  z-index: 99999;
`;
const Header = styled.div`
  background: ${(props) =>
    props.type === "error"
      ? props.theme.palette.error
      : props.theme.palette.primaryOutline};
  display: flex;
  min-height: 48px;
  padding: 0 16px;
`;
const Content = styled.div`
  padding: 0px 4px 0px 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: ${(props) => (props.minimized ? "0px" : "400px")};
  transition: all 300ms ease;
`;

const IconButton = styled.div`
  color: white;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.7;
  }
`;
const MinimizeIcon = styled.div`
  color: white;
  cursor: pointer;
  transition: all 300ms ease;
  transform: ${(props) =>
    props.minimized ? "rotate(0deg)" : "rotate(180deg)"};
  &:hover {
    opacity: 0.7;
  }
`;

const CancelButton = styled.div`
  color: ${(props) => props.theme.palette.white};
  font-size: ${(props) => props.theme.font.size.s};
  cursor: pointer;
  text-decoration: underline;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.5;
  }
`;
