import React from "react";
import { Button, Alert } from "components";

export const UploadParseFailedModal = ({ onRemove, file, ...props }) => {
  return (
    <Alert color={"error"} size="small" title="Error: Parse failed" {...props}>
      <div className="mt-4 d-flex">
        <Button
          className="ml-auto"
          size="small"
          buttonTheme="dark"
          width={400}
          onClick={() => {
            props.onClose();
            onRemove(file);
          }}
        >
          Remove Upload
        </Button>
      </div>
    </Alert>
  );
};
