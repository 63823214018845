import React from "react";
import styled from "styled-components";
import { SortIcon } from "./";

export const TableHeader = ({ sortCol, sortType, onSort = () => {} }) => {
  return (
    <Container>
      <RowItem></RowItem>
      <SortCol
        name="title"
        label="Name"
        sortCol={sortCol}
        sortType={sortType}
        onSort={onSort}
      />
      <SortCol
        name="status"
        label="Status"
        sortCol={sortCol}
        sortType={sortType}
        onSort={onSort}
      />
      <SortCol
        name="duration"
        label="Duration"
        sortCol={sortCol}
        sortType={sortType}
        onSort={onSort}
      />
      <RowItem>Channel</RowItem>
      <RowItem>Exceptions</RowItem>
      <SortCol
        name="creation_time"
        label="Uploaded Date"
        sortCol={sortCol}
        sortType={sortType}
        onSort={onSort}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 0 32px 0 32px;
  color: white;
  font-size: ${(props) => props.theme.palette.m};
  margin: 28px 12px 15px 12px;
  & > div:nth-child(1) {
    width: 120px;
  }
  & > div:nth-child(2) {
    width: 200px;
  }
  & > div:nth-child(3) {
    width: 120px;
  }
  & > div:nth-child(4) {
    width: 150px;
  }
  & > div:nth-child(5) {
    width: 150px;
  }
  & > div:nth-child(6) {
    flex: 1;
  }
  & > div:nth-child(7) {
    width: 190px;
    & > div {
      margin-left: -14px;
    }
  }
`;

const RowItem = styled.div`
  margin: auto 32px auto 0;
`;

const SortCol = ({ name, label, onSort, sortCol, sortType }) => {
  const handleSort = () => {
    onSort(name, sortType === 1 ? 2 : 1);
  };

  return (
    <RowItem>
      <div className="d-flex">
        <span onClick={handleSort} className="my-auto cursor-pointer">
          {label}
        </span>
        <SortIcon type={sortCol === name ? sortType : 0} onClick={handleSort} />
      </div>
    </RowItem>
  );
};
