import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { last } from "lodash";
import { getROIImage } from "api";
import { useLocation } from "@reach/router";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";

export const BrandOffer = ({ title, desc, roi, value, description, type }) => {
  const [isLoadingThumb, setIsLoadingThumb] = useState(false);
  const [thumbSrc, setThumbSrc] = useState("");
  const location = useLocation();

  const fetchThumb = async (params) => {
    setIsLoadingThumb(true);
    const { success, payload } = await getROIImage(
      last(location.pathname.split("/")),
      params
    );
    if (success) {
      setThumbSrc(payload);
    } else {
      setThumbSrc("");
    }
    setIsLoadingThumb(false);
  };

  useEffect(() => {
    if (roi) {
      console.log("brand roi", roi);
      fetchThumb(roi);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roi]);

  return (
    <Container>
      {/* <Title>
        <span>{title}</span>
        <span className="desc">{desc}</span>
      </Title> */}
      <div className="d-flex mt-2">
        <BrandsSection>
          <Header>Brand</Header>
          <Body>
            {isLoadingThumb ? (
              <ProductImgContainer>
                <RingLoader
                  color="white"
                  size={80}
                  css={css`
                    margin: auto;
                  `}
                />
              </ProductImgContainer>
            ) : (
              <>
                {thumbSrc && (
                  <ProductImg
                    src={`data:image/jpeg;base64,${thumbSrc}`}
                    data-for="product-desc"
                    data-tip={desc}
                  />
                )}
                <div className="mt-2 text-center">{}</div>
              </>
            )}
          </Body>
        </BrandsSection>
        <ValueSection>
          <Header>Value</Header>
          <Body>{`${value}$`}</Body>
        </ValueSection>
        <DescriptionSection>
          <Header>Type</Header>
          <Body>{type}</Body>
        </DescriptionSection>
      </div>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 0 24px 0;
`;
// const Title = styled.div`
//   font-size: 20px;
//   color: white;
//   border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
//   display: flex;
//   .desc {
//     font-size: 16px;
//     color: ${(props) => props.theme.palette.secondary};
//     margin: auto 0 0 20px;
//   }
// `;
const BrandsSection = styled.div`
  width: 230px;
  margin-right: 4px;
  display: flex;
  flex-direction: column;
`;
const ValueSection = styled.div`
  width: 120px;
  margin-right: 4px;
  display: flex;
  flex-direction: column;
`;
const DescriptionSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  background: ${(props) => props.theme.palette.items};
  color: white;
  font-size: 16px;
  height: 28px;
  line-height: 28px;
  padding: 0 24px;
`;
const Body = styled.div`
  background: ${(props) => props.theme.palette.gray};
  color: white;
  padding: 24px;
  flex: 1;
`;

// const Brand = ({ logo, name }) => (
//   <BrandContainer>
//     <BrandLogo />
//     <BrandName>{name}</BrandName>
//   </BrandContainer>
// );
// const BrandContainer = styled.div`
//   width: 96px;
//   margin-right: 16px;
//   margin-bottom: 16px;
// `;
// const BrandLogo = styled.div`
//   width: 96px;
//   height: 96px;
//   color: white;
//   background: #f6c4c4;
// `;
// const BrandName = styled.div`
//   color: white;
//   font-size: 16px;
// `;

const ProductImg = styled.img`
  width: 180px;
  height: 180px;
  object-fit: contain;
  border: 1px solid #e2e2e2;
`;

const ProductImgContainer = styled.div`
  width: 180px;
  height: 180px;
  display: flex;
`;
