import React from "react";
import styled from "styled-components";
import { FaTrash } from "react-icons/fa";
import { usePostContext } from "contexts";
import { useInput } from "hooks";
import { Modal, SearchInput, Button, DropDown } from "components";
import { PIN_FIELDS } from "config";

export const PinFieldModal = ({ open, onClose, onConfirm = () => {} }) => {
  const field = useInput();
  const search = useInput();
  const { fieldPinData, setFieldPinData } = usePostContext();

  const handleAdd = () => {
    if (!fieldPinData.includes(field.value))
      setFieldPinData([...fieldPinData, field.value]);
  };

  const handleRemove = (params) => {
    console.log("remove", params);
    setFieldPinData(fieldPinData.filter((value) => value !== params));
  };

  return (
    <Modal open={open} size="medium" onClose={onClose} title={"Pin a Field"}>
      <div className="d-flex">
        <DropDown
          className="flex-1"
          data={PIN_FIELDS.filter((item) => !fieldPinData.includes(item.id))}
          width="100%"
          {...field}
        />
        <Button
          size="small"
          buttonTheme="dark"
          onClick={handleAdd}
          className="ml-2"
          width="80px"
        >
          Add
        </Button>
      </div>
      <div className="d-flex mt-2">
        <SearchInput
          className="w-100"
          placeholder="Filter pinned fields"
          {...search}
        />
      </div>
      <ItemContent className="mt-2">
        {fieldPinData
          .filter((value) =>
            PIN_FIELDS.find((item) => item.id === value)
              ?.title?.toLocaleLowerCase()
              .includes(search.value.toLocaleLowerCase())
          )
          .map((value, index) => (
            <Item
              key={`${index}key`}
              value={PIN_FIELDS.find((item) => item.id === value)?.title}
              onDelete={() => handleRemove(value)}
            />
          ))}
      </ItemContent>
      <div className="d-flex mt-4">
        <Button
          size="small"
          buttonTheme="dark"
          width="80px"
          className="ml-auto"
          onClick={() => onClose()}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  max-height: 400px;
  flex: 1;
  margin-right: 4px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const Item = ({ value, onDelete = () => {} }) => {
  return (
    <ItemContainer>
      <div className="my-auto ml-3">{value}</div>
      <ItemDelete className="ml-auto my-auto mr-2" onClick={onDelete}>
        <FaTrash color="white" size={14} />
      </ItemDelete>
    </ItemContainer>
  );
};
const ItemContainer = styled.div`
  display: flex;
  height: 44px;
  min-height: 44px;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  &:nth-child(2n) {
    background: ${(props) => props.theme.palette.items};
  }
`;
const ItemDelete = styled.div`
  color: white;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.7;
  }
`;
