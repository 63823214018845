import React from "react";
import { Button, Alert } from "components";
import styled from "styled-components";
import { navigate } from "@reach/router";

export const UploadDuplicateModal = ({
  onRemove = () => {},
  file,
  ...props
}) => {
  const { post_hash } = file;
  const handleViewAsset = () => {
    navigate(`/post/${post_hash}`);
  };

  return (
    <Alert
      color={"error"}
      size="small"
      title="Error: Duplicate Asset"
      {...props}
    >
      <Content>
        This asset already exists in this account.
        <ViewSupportedFiles onClick={handleViewAsset} className="ml-3">
          VIEW ASSET
        </ViewSupportedFiles>
      </Content>
      <div className="mt-4 d-flex">
        <Button
          className="ml-auto"
          size="small"
          width={400}
          buttonTheme="dark"
          onClick={() => {
            props.onClose();
            onRemove(file);
          }}
        >
          Remove Upload
        </Button>
      </div>
    </Alert>
  );
};

const ViewSupportedFiles = styled.div`
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
  transition: 300ms ease all;
  &:hover {
    opacity: 0.7;
  }
`;

const Content = styled.div`
  font-size: 16px;
  display: flex;
`;
