import React, { useState } from "react";
import styled from "styled-components";
import { FaUserAlt, FaUserEdit } from "react-icons/fa";
import { useAuthContext } from "contexts";
export const UserMode = ({ className }) => {
  const [isExpand, setIsExpand] = useState(false);
  const { userMode, setUserMode } = useAuthContext();

  const handleClickMode = (e, params) => {
    e.stopPropagation();
    if (isExpand) {
      setUserMode(params);
      setIsExpand(false);
    } else {
      setIsExpand(true);
    }
  };
  return (
    <Container isExpand={isExpand} className={className}>
      {isExpand ? (
        userMode === "normal" ? (
          <>
            <FaUserAlt
              color="#131313"
              onClick={(e) => handleClickMode(e, "normal")}
              size={20}
            />
            <FaUserEdit
              color="#131313"
              onClick={(e) => handleClickMode(e, "qc")}
              size={20}
            />
          </>
        ) : (
          <>
            <FaUserEdit
              color="#131313"
              onClick={(e) => handleClickMode(e, "qc")}
              size={20}
            />
            <FaUserAlt
              color="#131313"
              onClick={(e) => handleClickMode(e, "normal")}
              size={20}
            />
          </>
        )
      ) : userMode === "normal" ? (
        <FaUserAlt
          color="#fff"
          size={20}
          onClick={(e) => handleClickMode(e, "normal")}
        />
      ) : (
        <FaUserEdit
          color="#fff"
          size={20}
          onClick={(e) => handleClickMode(e, "qc")}
        />
      )}
    </Container>
  );
};
const Container = styled.div`
  border-radius: 16px;
  width: 32px;
  background: ${(props) => (props.isExpand ? "white" : "transparent")};
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.isExpand ? "64px" : "32px")};
  position: relative;
  z-index: 1;
  cursor: pointer;
  svg {
    margin: 6px;
  }
`;
