import React from "react";
import { Section, Celebrity } from "./../components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const CelebritiesSection = ({ data, isLoading }) => {
  return isLoading ? (
    <Section title="Celebrities">
      <SkeletonTheme color="#202020" highlightColor="#444">
        <Skeleton count={3} />
      </SkeletonTheme>
    </Section>
  ) : data?.length > 0 ? (
    <Section title="Celebrities">
      <div className=" d-flex flex-wrap">
        {data.map((brand, index) => (
          <Celebrity data={brand} key={`${index}key`} />
        ))}
      </div>
    </Section>
  ) : null;
};
