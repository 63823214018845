import React from "react";
import { Button, Alert } from "components";

export const UploadCloseModal = ({
  state,
  onContinue = () => {},
  onCancel,
  ...props
}) => {
  return (
    <Alert
      color={state === "error" ? "error" : "primary"}
      title="Cancel upload?"
      size="small"
      {...props}
    >
      <div>
        Your upload is not complete. Would you like to cancel your upload?
      </div>
      <div className="mt-4 d-flex">
        <Button
          className="ml-auto"
          size="small"
          width={400}
          onClick={() => {
            props.onClose();
            onContinue();
          }}
        >
          Continue Upload
        </Button>
        <Button
          className="ml-3"
          size="small"
          buttonTheme="dark"
          width={400}
          onClick={() => {
            props.onClose();
            onCancel();
          }}
        >
          Cancel Upload
        </Button>
      </div>
    </Alert>
  );
};
