import { API } from "utils";

export const getAppInfoApi = async (search = "") => {
  try {
    const response = await API().get(`api/get_app_info`);
    return response.data;
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};
