import React from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const FilterHeader = ({
  assets,
  totalAssets,
  loadingTime,
  isLoading,
}) => {
  return (
    <Container>
      {isLoading ? (
        <div className="w-100">
          <SkeletonTheme color="#202020" highlightColor="#444">
            <Skeleton height={24} className="w-100 d-flex" />
          </SkeletonTheme>
        </div>
      ) : (
        <div className="d-flex flex-column w-100">
          <div className="title">
            {assets} / {totalAssets} assets
          </div>
          <div className="ml-auto text-right time">loaded in {loadingTime}</div>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  /* height: 50px; */
  border-bottom: 4px solid ${(props) => props.theme.palette.canvas};
  color: ${(props) => props.theme.palette.white};
  font-size: ${(props) => props.theme.font.size.xm};
  padding: 12px 24px 6px 24px;
  .time {
    font-size: ${(props) => props.theme.font.size.xs};
    color: ${(props) => props.theme.palette.secondary};
  }
`;
