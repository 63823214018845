import React from "react";
import { Section } from "./../components";
import { TextAudio } from "./TextAudio";
import { TextVisual } from "./TextVisual";

export const TextSection = ({ post, isLoading, texts }) => {
  return (
    <Section title="Text">
      <div className="d-flex">
        <TextVisual post={post} isLoading={isLoading} data={texts} />
        <TextAudio
          isLoading={isLoading}
          data={post?.cert?.meta?.stt?.english}
        />
      </div>
    </Section>
  );
};
