import React from "react";
import styled from "styled-components";
import { Accordion, CheckBox } from "components";

export const FilterSection = () => {
  return (
    <AccordionSection>
      <Accordion title="Status">
        <CheckBox className="my-2">Unscanned</CheckBox>
        <CheckBox className="my-2">Scanned</CheckBox>
        <CheckBox className="my-2">Processing</CheckBox>
      </Accordion>
      <Accordion title="Channel">
        <CheckBox className="my-2">Unscanned</CheckBox>
        <CheckBox className="my-2">Scanned</CheckBox>
        <CheckBox className="my-2">Processing</CheckBox>
      </Accordion>
      <Accordion title="Exceptions">
        <CheckBox>Unscanned</CheckBox>
        <CheckBox>Scanned</CheckBox>
        <CheckBox>Processing</CheckBox>
      </Accordion>
      <Accordion title="Upload Date">
        <CheckBox>Unscanned</CheckBox>
        <CheckBox>Scanned</CheckBox>
        <CheckBox>Processing</CheckBox>
      </Accordion>
      <Accordion title="Duration" desc="in minutes">
        <CheckBox>Unscanned</CheckBox>
        <CheckBox>Scanned</CheckBox>
        <CheckBox>Processing</CheckBox>
      </Accordion>
      <Accordion title="Global Tags" desc="10000 tags">
        <CheckBox>Unscanned</CheckBox>
        <CheckBox>Scanned</CheckBox>
        <CheckBox>Processing</CheckBox>
      </Accordion>
      <Accordion title="Private Tags" desc="10000 tags">
        <CheckBox>Unscanned</CheckBox>
        <CheckBox>Scanned</CheckBox>
        <CheckBox>Processing</CheckBox>
      </Accordion>
      <Accordion title="Categories" desc="10000 categories">
        <CheckBox>Unscanned</CheckBox>
        <CheckBox>Scanned</CheckBox>
        <CheckBox>Processing</CheckBox>
      </Accordion>
      <Accordion title="Brands" desc="10000 brands">
        <CheckBox>Unscanned</CheckBox>
        <CheckBox>Scanned</CheckBox>
        <CheckBox>Processing</CheckBox>
      </Accordion>
      <Accordion title="Features" desc="10000 features">
        <CheckBox>Unscanned</CheckBox>
        <CheckBox>Scanned</CheckBox>
        <CheckBox>Processing</CheckBox>
      </Accordion>
      <Accordion title="Mediums" desc="10000 mediums">
        <CheckBox>Unscanned</CheckBox>
        <CheckBox>Scanned</CheckBox>
        <CheckBox>Processing</CheckBox>
      </Accordion>
      <Accordion title="Confirmations" desc="10000 manufacturers">
        <CheckBox>Unscanned</CheckBox>
        <CheckBox>Scanned</CheckBox>
        <CheckBox>Processing</CheckBox>
      </Accordion>
      <Accordion title="Manufacturers" desc="10000 manufacturers">
        <CheckBox>Unscanned</CheckBox>
        <CheckBox>Scanned</CheckBox>
        <CheckBox>Processing</CheckBox>
      </Accordion>
    </AccordionSection>
  );
};

const AccordionSection = styled.div`
  margin: 24px 4px 4px 24px;
  padding-right: 10px;
  overflow: auto;
  flex: 1;
  ::-webkit-scrollbar {
    width: 10px;
    background: ${(props) => props.theme.palette.background};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.background};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
