/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { VideoPlayer, DropDown, Cropper } from "components";
import ReactTooltip from "react-tooltip";
import { useInput } from "hooks";
import { usePostContext } from "contexts";
import { MdCropFree } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import { API_URL } from "config";
import captureFrame from "capture-frame";

const sourceData = [
  { id: "raw", title: "Original" },
  { id: "full", title: "Watch" },
  { id: "screenshot", title: "Screenshot" },
  { id: "micro", title: "Micro" },
  { id: "thumbnail", title: "Thumbnail" },
];
export const PlayerFragment = ({ postId, onCrop, isLoading, creditSize }) => {
  const source = useInput(sourceData[0].id);
  const { post, setToolbarState, toolBarState } = usePostContext();
  const cropperRef = useRef();
  const playerRef = useRef();
  const [token, setToken] = useState("");
  const [zoomRatio, setZoomRatio] = useState(1);
  const [cornerX, setCornerX] = useState(0);
  const [cornerY, setCornerY] = useState(0);
  const [srcUrl, setSrcUrl] = useState("");
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    setToken(localStorage.getItem("user"));
    return () => {};
  }, []);

  useEffect(() => {
    if (post && post.credit_size !== 1) {
      source.setValue("raw");
    } else {
      source.setValue("thumbnail");
    }
    return () => {};
  }, [post]);

  useEffect(() => {
    setToolbarState("move");
    setZoomRatio(1);
    setCornerX(0);
    setCornerY(0);
    setSource();
    return () => {};
  }, [source.value, token, postId]);

  const setSource = () => {
    setSrcUrl(
      `${API_URL}/api/${
        source.value === "thumbnail"
          ? "thumb"
          : source.value === "screenshot"
          ? "rscreen"
          : source.value === "micro"
          ? "micro"
          : source.value === "raw"
          ? "watch"
          : "full"
      }/${postId}?jwt=${token}`
    );
  };

  const handleClickCrop = (time = 0) => {
    const state = toolBarState === "move" ? "crop" : "move";
    setToolbarState(state);

    if (
      (source.value === "raw" || source.value === "full") &&
      playerRef.current
    ) {
      setCurrentTime(playerRef.current.pause());
      if (state === "crop") {
        setCornerX(0);
        setCornerY(0);
        setZoomRatio(1);
        const frame = captureFrame(".video-react-video");
        const url = window.URL.createObjectURL(new window.Blob([frame.image]));
        setSrcUrl(url);
      } else {
        setSource();
      }
    } else {
      setSource();
    }
  };

  const handleCrop = (params) => {
    onCrop({
      ...params,
      frame:
        source.value === "thumbnail"
          ? -1
          : source.value === "screenshot"
          ? "rscreen"
          : currentTime,
    });
  };

  const handleReset = () => {
    setZoomRatio(1);
    if (
      (source.value === "raw" || source.value === "full") &&
      toolBarState === "move"
    ) {
      setCornerX(0);
      setCornerY(0);
    } else {
      cropperRef.current && cropperRef.current.resetZoom();
    }
  };
  const handleChangeZoom = (params) => {
    setZoomRatio(params);
  };

  return (
    <Container>
      <PlayerContainer>
        <Header>
          <DropDown
            className="my-auto"
            {...source}
            data={sourceData}
            width={120}
          />

          <ActionButton
            onClick={() => handleClickCrop()}
            active={toolBarState === "crop"}
            className="ml-auto my-auto"
          >
            <MdCropFree color="white" size={24} />
          </ActionButton>
          <OutlineButton
            active={toolBarState === "select"}
            className="ml-2 my-auto"
            style={{ width: 80 }}
          >
            <BsSearch />
            <span className="ml-1">{Math.floor(zoomRatio * 100)}%</span>
          </OutlineButton>
          <OutlineButton
            onClick={handleReset}
            active={toolBarState === "select"}
            className="ml-2 my-auto"
          >
            RESET
          </OutlineButton>
        </Header>
        {(source.value === "raw" || source.value === "full") &&
        toolBarState === "move" ? (
          <VideoPlayer
            postId={postId}
            source={source.value}
            srcUrl={srcUrl}
            cropperRef={cropperRef}
            creditSize={creditSize}
            toolBarState={toolBarState}
            onZoom={handleChangeZoom}
            onMove={(x, y) => {
              setCornerX((state) => state + x);
              setCornerY((state) => state + y);
            }}
            zoomRatio={zoomRatio}
            isLoading={isLoading}
            cornerX={cornerX}
            cornerY={cornerY}
            ref={playerRef}
            startTime={currentTime}
            visible={toolBarState === "move"}
          />
        ) : (
          <Cropper
            source={srcUrl}
            onCrop={handleCrop}
            onZoom={handleChangeZoom}
            isLoading={isLoading}
            ref={cropperRef}
            onCancelCrop={handleClickCrop}
          />
        )}
      </PlayerContainer>
      <ReactTooltip
        id="player-tools"
        className="player-tools"
        place="top"
        multiline
        effect="solid"
        brand-tooltip="#000"
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 442px;
`;
const Header = styled.div`
  height: 56px;
  min-height: 56px;
  display: flex;
`;

const PlayerContainer = styled.div`
  flex: 1;
  min-width: 600px;
  display: flex;
  flex-direction: column;
  margin: 2px;
  padding: 0px 16px 16px 16px;
  color: ${(props) => props.theme.palette.white};
  background: ${(props) => props.theme.palette.backgrounds};
  font-size: ${(props) => props.theme.font.size.sm};
`;

const ActionButton = styled.div`
  width: 42px;
  height: 100%;
  cursor: pointer;
  transition: all 300ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.active ? props.theme.palette.itemsHover : "transparent"};
  &:hover {
    opacity: 0.7;
    background: ${(props) => props.theme.palette.itemsHover};
  }
`;

const OutlineButton = styled.div`
  color: #fff;
  background: #191919;
  border: 1px solid #2b2b2b;
  padding: 4px 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
