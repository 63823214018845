import React from "react";
import styled from "styled-components";
import { Box } from "./../components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
export const ValuesPanel = ({
  brands,
  products,
  offers,
  text,
  audio,
  category,
  web,
  isLoading,
}) => {
  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container>
        <div className="d-flex">
          <Label>BRANDS</Label>
          <Value className="py-auto">
            <Skeleton height={20} width={30} />
          </Value>
        </div>
        <div className="d-flex">
          <Label>PRODUCTS</Label>
          <Value>
            {" "}
            <Skeleton height={20} width={30} />
          </Value>
        </div>
        <div className="d-flex">
          <Label>OFFERS</Label>
          <Value>
            <Skeleton height={20} width={30} />
          </Value>
        </div>
        <div className="d-flex">
          <Label>TEXT</Label>
          <Value>
            {" "}
            <Skeleton height={20} width={30} />
          </Value>
        </div>
        <div className="d-flex">
          <Label>AUDIO</Label>
          <Value>
            {" "}
            <Skeleton height={20} width={30} />
          </Value>
        </div>
        <div className="d-flex">
          <Label>CATEGORY</Label>
          <Value>
            {" "}
            <Skeleton height={20} width={30} />
          </Value>
        </div>
        <div className="d-flex">
          <Label>WEB</Label>
          <Value>
            {" "}
            <Skeleton height={20} width={30} />
          </Value>
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    <Container>
      <div className="d-flex">
        <Label>BRANDS</Label>
        <Value>{brands}</Value>
      </div>
      <div className="d-flex">
        <Label>PRODUCTS</Label>
        <Value>{products}</Value>
      </div>
      <div className="d-flex">
        <Label>OFFERS</Label>
        <Value>{offers}</Value>
      </div>
      <div className="d-flex">
        <Label>TEXT</Label>
        <Value>{text}</Value>
      </div>
      <div className="d-flex">
        <Label>AUDIO</Label>
        <Value>{audio}</Value>
      </div>
      <div className="d-flex">
        <Label>CATEGORY</Label>
        <Value>{category}</Value>
      </div>
      <div className="d-flex">
        <Label>WEB</Label>
        <Value>{web}</Value>
      </div>
    </Container>
  );
};

const Container = styled(Box)`
  display: flex;
  padding: 20px 20px;
  justify-content: space-between;
`;

const Label = styled.div`
  font-size: 16px;
  line-height: 16px;
  color: white;
  margin: auto;
`;
const Value = styled.div`
  font-size: 24px;
  line-height: 20px;
  color: ${(props) => props.theme.palette.secondary};
  margin-left: 4px;
`;
