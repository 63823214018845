import React, { useState } from "react";
import { usePostContext } from "contexts";
import { Modal, SearchInput, Button } from "components";
import { getReport } from "api";
import RiseLoader from "react-spinners/RiseLoader";
import { css } from "@emotion/react";

export const ReportModal = ({ open, onClose, onConfirm = () => {} }) => {
  const { query, posts } = usePostContext();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async () => {
    setIsDownloading(true);
    await getReport(query);
    onClose();
    onConfirm();
    setIsDownloading(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      isCloseDisabled={isDownloading}
      title={isDownloading ? "Downloading..." : "Report Confirmation"}
    >
      {isDownloading ? (
        <div className="d-flex py-4 my-4">
          <RiseLoader
            css={css`
              margin: auto;
            `}
            color="#006280"
            size={20}
            margin={2}
          />
        </div>
      ) : (
        <>
          <div className="mt-3 mb-4">
            {isDownloading
              ? "Downloading..."
              : "Create a report from the following query:"}
          </div>
          <SearchInput
            value={
              query || "No query selected, pulling from the entire account"
            }
          />
          <div className="mt-4">This consists of {posts.length} records.</div>
          <div className="d-flex mt-4">
            <Button
              size="normal"
              buttonTheme="dark"
              width="fit-content"
              className="ml-auto"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="normal"
              buttonTheme="primary"
              width="fit-content"
              className="ml-4"
              onClick={handleDownload}
            >
              Confirm
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};
