import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Text, Desc } from "components";
import { BsFillCaretDownFill } from "react-icons/bs";

export const TagAccordion = ({
  title,
  desc,
  children,
  className,
  open,
  name,
  setOpen = () => {},
}) => {
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef();
  useEffect(() => {
    setContentHeight(contentRef.current.clientHeight + 32);
    return () => {};
  }, []);
  return (
    <Container className={className}>
      <Main onClick={() => setOpen(open ? "" : name)}>
        <Text className="my-auto">{title}</Text>
        <Desc className="ml-2 mt-auto">{desc}</Desc>
        <ArrowIcon className="my-auto" open={open}>
          <BsFillCaretDownFill size={12} />
        </ArrowIcon>
      </Main>
      <Content open={open} maxHeight={contentHeight}>
        <div className="m-3" ref={contentRef}>
          {children}
        </div>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin: 2px 0;
  border-radius: 1px;
`;

const Main = styled.div`
  background: ${(props) => props.theme.palette.items};
  height: 30px;
  display: flex;
  cursor: pointer;
  transition: all 300ms ease;
  padding: 0 8px 0 14px;
  &:hover {
    opacity: 0.8;
  }
`;

const ArrowIcon = styled.div`
  transition: all 300ms ease;
  margin-left: auto;
  transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0deg)")};
  color: white;
`;
const Content = styled.div`
  overflow: hidden;
  transition: all 300ms ease;
  max-height: ${(props) => (props.open ? `${props.maxHeight}px` : 0)};
  font-size: ${(props) => props.theme.font.size.m};
  background: ${(props) => props.theme.palette.gray};
`;
