/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Cropper from "react-cropper";
import { Button } from "components";
import "cropperjs/dist/cropper.css";

export const ImageCropper = ({
  cropperContainerRef,
  toolBarState,
  source,
  cropperSource,
  cropperRef,
  onCrop = () => {},
  onCancel = () => {},
}) => {
  const [buttonState, setButtonState] = useState({ x: 0, y: 0, show: false });
  const handleCapture = () => {
    console.log(
      "capture",
      cropperRef.current.cropper.getCropBoxData(),
      cropperRef.current.cropper.getCanvasData()
    );
    onCrop({
      tl: `${(
        (cropperRef.current.cropper.getCropBoxData().left -
          cropperRef.current.cropper.getCanvasData().left) /
        cropperRef.current.cropper.getCanvasData().width
      ).toFixed(5)},${(
        (cropperRef.current.cropper.getCropBoxData().top -
          cropperRef.current.cropper.getCanvasData().top) /
        cropperRef.current.cropper.getCanvasData().height
      ).toFixed(5)}`,
      br: `${(
        (cropperRef.current.cropper.getCropBoxData().left -
          cropperRef.current.cropper.getCanvasData().left +
          cropperRef.current.cropper.getCropBoxData().width) /
        cropperRef.current.cropper.getCanvasData().width
      ).toFixed(5)},${(
        (cropperRef.current.cropper.getCropBoxData().top -
          cropperRef.current.cropper.getCanvasData().top +
          cropperRef.current.cropper.getCropBoxData().height) /
        cropperRef.current.cropper.getCanvasData().height
      ).toFixed(5)}`,
    });
  };
  const handleCancel = () => {
    setButtonState({ x: 0, y: 0, show: false });
    cropperRef.current.cropper.clear();
    onCancel();
  };

  useEffect(() => {
    if (!toolBarState) {
      setButtonState({ x: 0, y: 0, show: false });
      cropperRef.current.cropper.clear();
    }
    return () => {};
  }, [toolBarState]);

  return (
    <CropperContainer
      ref={cropperContainerRef}
      active={toolBarState}
      source={source}
    >
      <Cropper
        // src={`${API_URL}/api/thumb/${postId}?jwt=${token}`}
        src={cropperSource}
        style={{ height: "100%" }}
        dragMode="crop"
        guides={false}
        background={false}
        autoCrop={false}
        center={true}
        viewMode={1}
        ref={cropperRef}
        cropend={(e) => {
          setButtonState({
            x:
              cropperRef.current.cropper.getCropBoxData().left +
              cropperRef.current.cropper.getCropBoxData().width / 2 -
              66,
            y:
              cropperRef.current.cropper.getCropBoxData().top +
              cropperRef.current.cropper.getCropBoxData().height +
              168,
            show: true,
          });
        }}
        cropstart={(e) => {
          setButtonState({ x: 0, y: 0, show: false });
        }}
      />

      <ButtonContainer {...buttonState}>
        <Button
          size="small"
          width="80px"
          buttonTheme="dark"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          size="small"
          width="80px"
          className="ml-3"
          onClick={handleCapture}
        >
          OK
        </Button>
      </ButtonContainer>
    </CropperContainer>
  );
};

const CropperContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  /* height: ${(props) =>
    props.source === "thumbnail" ? "100%" : "calc(100% - 32px)"}; */
  visibility: ${(props) => (props.active ? "visible" : "hidden")};
  background: black;
`;

const ButtonContainer = styled.div`
  position: fixed;
  left: ${(props) => `${props.x}px`};
  top: ${(props) => `${props.y}px`};
  z-index: 99;
  display: ${(props) => (props.show ? "block" : "none")};
`;
