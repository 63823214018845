import { API } from "utils";

export const createCheckoutSessionApi = async (params) => {
  try {
    const response = await API().post(`/api/create-checkout-session/${params}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: e };
  }
};
