import { API } from "utils";

export const getTagNames = async (search = "") => {
  try {
    const response = await API().get(`api/search_tag_names?search=${search}`);
    return response.data;
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};
