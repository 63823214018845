import { useState } from "react";

export const useModal = (initialValue = false) => {
  const [open, setOpen] = useState(initialValue);

  return {
    open,
    onClose: () => setOpen(false),
    openModal: () => setOpen(true),
  };
};
