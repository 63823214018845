import React, { useState, useEffect } from "react";
import { Modal, Button } from "components";
import { loadStripe } from "@stripe/stripe-js";
import { createCheckoutSessionApi } from "api";

export const PricingModal = ({ open, onClose, onSelect = () => {} }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [plan, setPlan] = useState("");

  const handleSelect = async (params) => {
    setIsLoading(true);
    setPlan(params);
    const res = await createCheckoutSessionApi(params);
    const { id } = res.payload;
    let stripePromise = loadStripe("pk_test_GrFNGzOKdNt89S44psuqAQRO");
    const stripe = await stripePromise;
    let result = stripe.redirectToCheckout({ sessionId: id });
    if (result.error) {
      console.log("stripe error", result.error);
    }
    setIsLoading(false);
    onClose();
    onSelect(params);
  };

  useEffect(() => {
    if (open) {
      setPlan("");
    }
    return () => {};
  }, [open]);

  return (
    <Modal
      open={open}
      size="small"
      onClose={onClose}
      title="Please choose your plan."
    >
      <div className="mb-4">
        <Button
          size="large"
          buttonTheme="primary"
          isLoading={plan === "small" && isLoading}
          onClick={() => handleSelect("small")}
        >
          67.90$ <small> (10,000 credits)</small>
        </Button>
        <Button
          size="large"
          buttonTheme="primary"
          className="mt-4"
          isLoading={plan === "medium" && isLoading}
          onClick={() => handleSelect("medium")}
        >
          670.00$ <small>(100,000 credits)</small>
        </Button>
        <Button
          size="large"
          buttonTheme="primary"
          className="mt-4"
          isLoading={plan === "big" && isLoading}
          onClick={() => handleSelect("big")}
        >
          6000.00$ <small>(1,000,000 credits)</small>
        </Button>
      </div>
    </Modal>
  );
};
