import React from "react";
import styled from "styled-components";

const StyledLink = styled.a`
  color: ${(props) => props.theme.palette.white};
  font-size: ${(props) => props.theme.font.size.sm};
  cursor: pointer;
  transition: all 300ms ease;
  text-decoration: underline;
  &:hover {
    color: ${(props) => props.theme.palette.primary};
  }
`;

export const Link = ({ children, ...props }) => {
  return <StyledLink {...props}>{children}</StyledLink>;
};
