import React from "react";
import styled from "styled-components";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";

export const SortIcon = ({ type, onClick = () => {} }) => {
  return (
    <div
      className="d-flex ml-1 flex-column justify-content-center"
      onClick={onClick}
    >
      <Icon disabled={type !== 1}>
        <BsFillCaretUpFill size={10} />
      </Icon>
      <Icon disabled={type !== 2}>
        <BsFillCaretDownFill size={10} />
      </Icon>
    </div>
  );
};

const Icon = styled.div`
  cursor: pointer;
  width: 12px;
  height: 12px;
  height: fit-content;
  transition: all 300ms ease;
  display: flex;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  svg {
    margin: auto;
  }
`;
