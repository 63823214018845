import React from "react";
import styled from "styled-components";
import { Tag } from "./Tag";
export const TagsPane = ({ data = [], onRemove = () => {}, className }) => {
  return (
    <Container className={className}>
      <div className="label">TAGS:</div>
      <div className="ml-2 d-flex flex-column">
        {data.length === 0
          ? "No tags associated to this field, add a tag above."
          : data.map((item, index) => (
              <Tag
                key={`${index}key`}
                isRemovable
                value={item.value}
                onRemove={() => onRemove(item.id)}
              />
            ))}
      </div>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  color: ${(props) => props.theme.palette.white};
  background: ${(props) => props.theme.palette.gray};
  font-size: ${(props) => props.theme.font.size.m};
  padding: 16px;
  .label {
    color: ${(props) => props.theme.palette.primaryHover};
    font-size: 16px;
    text-transform: uppercase;
  }
`;
