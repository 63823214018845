import React from "react";
import styled from "styled-components";

export const ProductOffer = ({
  title,
  desc,
  brands,
  oldValue,
  newValue,
  description,
  products,
}) => {
  return (
    <Container>
      <Title>
        <span>{title}</span>
        <span className="desc">{desc}</span>
      </Title>
      <div className="d-flex mt-2">
        <BrandsSection>
          <Header>Brands</Header>
          <Body>
            <div className="d-flex flex-wrap">
              {brands.map((brand) => (
                <Brand key={brand.name} {...brand} />
              ))}
            </div>
          </Body>
        </BrandsSection>
        <ValueSection>
          <Header>Old Value</Header>
          <Body>{oldValue}</Body>
        </ValueSection>
        <ValueSection>
          <Header>New Value</Header>
          <Body>{newValue}</Body>
        </ValueSection>
        <DescriptionSection>
          <Header>Description</Header>
          <Body>{description}</Body>
        </DescriptionSection>
        <ProductsSection>
          <Header>Products</Header>
          <Body>
            <div className="d-flex flex-wrap">
              {products.map((product) => (
                <Product key={product.name} {...product} />
              ))}
            </div>
          </Body>
        </ProductsSection>
      </div>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 0 24px 0;
`;
const Title = styled.div`
  font-size: 20px;
  color: white;
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  display: flex;
  .desc {
    font-size: 16px;
    color: ${(props) => props.theme.palette.secondary};
    margin: auto 0 0 20px;
  }
`;
const BrandsSection = styled.div`
  width: 280px;
  margin-right: 4px;
  display: flex;
  flex-direction: column;
`;

const ValueSection = styled.div`
  width: 120px;
  margin-right: 4px;
  display: flex;
  flex-direction: column;
`;
const DescriptionSection = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  margin-right: 4px;
`;
const ProductsSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  background: ${(props) => props.theme.palette.items};
  color: white;
  font-size: 16px;
  height: 28px;
  line-height: 28px;
  padding: 0 24px;
`;
const Body = styled.div`
  background: ${(props) => props.theme.palette.gray};
  color: white;
  padding: 24px;
  flex: 1;
`;

const Brand = ({ logo, name }) => (
  <BrandContainer>
    <BrandLogo />
    <BrandName>{name}</BrandName>
  </BrandContainer>
);
const BrandContainer = styled.div`
  width: 96px;
  margin-right: 16px;
  margin-bottom: 16px;
`;
const BrandLogo = styled.div`
  width: 96px;
  height: 96px;
  color: white;
  background: #f6c4c4;
`;
const BrandName = styled.div`
  color: white;
  font-size: 16px;
`;

const Product = ({ logo, name }) => (
  <ProductContainer>
    <ProductLogo />
    <ProductName>{name}</ProductName>
  </ProductContainer>
);
const ProductContainer = styled.div`
  width: 108px;
  margin-right: 16px;
  margin-bottom: 16px;
`;
const ProductLogo = styled.div`
  width: 108px;
  height: 108px;
  color: white;
  background: #f6c4c4;
`;
const ProductName = styled.div`
  color: white;
  font-size: 12px;
`;
