import React from "react";
import styled from "styled-components";

export const Section = ({ className, title, children, ...props }) => {
  return (
    <Container className={className} {...props}>
      <Header>
        <span className="my-auto">{title}</span>
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};

const Container = styled.div`
  /* width: 100%; */
  margin: 2px;
  padding: 16px;
  background-color: ${(props) => props.theme.palette.backgrounds};
`;
const Header = styled.div`
  display: flex;
  color: ${(props) => props.theme.palette.secondary};
  text-transform: uppercase;
  font-size: 14px;
`;
const Content = styled.div`
  padding: 8px;
  color: white;
  font-size: 14px;
`;
