import React from "react";
import styled from "styled-components";

export const SummaryItem2 = ({
  label,
  values = [],
  pin = false,
  link = false,
}) => {
  return (
    <Container pin={pin}>
      <Label pin={pin}>{label}:</Label>
      <Values>
        {values.map((value, index) =>
          link ? (
            <Link key={`${index}key`} href={value} target="_blank">
              {value}
            </Link>
          ) : (
            <Value key={`${index}key`}>{value}</Value>
          )
        )}
      </Values>
    </Container>
  );
};

const Container = styled.div`
  margin: 1px 0;
  padding: 12px 16px;
  display: flex;

  background: ${(props) => (props.pin ? "#F9F9BD24" : "")};
  &:nth-child(2n) {
    background: ${(props) =>
      props.pin ? "#F9F9BD24" : props.theme.palette.items};
  }
`;
const Label = styled.div`
  font-size: 16px;
  text-transform: uppercase;
  width: 200px;
  color: ${(props) =>
    props.pin
      ? props.theme.palette.accentOutline
      : props.theme.palette.primaryHover};
`;
const Values = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
`;

const Value = styled.div`
  color: white;
  font-size: 16px;
`;

const Link = styled.a`
  color: white;
  font-size: 16px;
  transition: all 300ms ease;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.palette.primary};
  }
`;
