import { DURATION_MIN, DURATION_MAX } from "config";

export const abbreviateName = (name = "") => {
  return name
    ? name
        // split the name into two words
        .split(" ")

        // take first char of both parts and capitalize them
        .map((part) => part[0].toUpperCase())

        // join both characters with a dot
        .join("")
        .slice(0, 2)
    : "";
};

export const extractTagFromQuery = (query = "", name = "") => {
  const startIndex = query.indexOf(name);
  if (startIndex === -1) {
    return { tag: "", rest: query };
  } else {
    let lastIndex = startIndex + query.slice(startIndex).indexOf(" ");
    if (lastIndex === -1) {
      lastIndex = query.length;
    }
    console.log("extract", startIndex, lastIndex, query.slice(lastIndex));
    return {
      tag: query.slice(startIndex + name.length, lastIndex).trim(),
      rest: query.slice(0, startIndex).trimLeft() + query.slice(lastIndex),
    };
  }
};

export const parseQuery = (query) => {
  const { tag: uploadDates, rest: query1 } = extractTagFromQuery(
    query,
    "upload-time:"
  );

  const { tag: creationDates, rest: query2 } = extractTagFromQuery(
    query1,
    "creation-time:"
  );

  const { tag: duration, rest: query3 } = extractTagFromQuery(
    query2,
    "duration:"
  );

  let tags = [];
  let subTags = [];
  let search = query3;
  do {
    const { tag: resTags, rest } = extractTagFromQuery(search, "tag:");
    subTags = resTags.split(",").filter((a) => a.trim().length);
    tags = [...tags, ...subTags];
    search = rest;
    console.log("search", subTags, search);
  } while (subTags.length > 0);

  subTags = [];
  do {
    const { tag: resTags, rest } = extractTagFromQuery(search, "target:");
    subTags = resTags.split(",").filter((a) => a.trim().length);
    tags = [...tags, ...subTags.map((a) => `target.${a}`)];
    search = rest;
    console.log("target", subTags, search);
  } while (subTags.length > 0);

  subTags = [];
  do {
    const { tag: resTags, rest } = extractTagFromQuery(search, "class:");
    subTags = resTags.split(",").filter((a) => a.trim().length);
    tags = [...tags, ...subTags.map((a) => `class.${a}`)];
    search = rest;
    console.log("class", subTags, search);
  } while (subTags.length > 0);

  return {
    uploadDates:
      uploadDates.split("..").length === 2 ? uploadDates.split("..") : ["", ""],
    creationDates:
      creationDates.split("..").length === 2
        ? creationDates.split("..")
        : ["", ""],
    duration:
      duration.split("..").length === 2
        ? {
            min: parseInt(duration.split("..")[0]),
            max: parseInt(duration.split("..")[1]),
          }
        : { min: DURATION_MIN, max: DURATION_MAX },
    tags: tags.filter((a) => a.trim().length),
    search,
  };
};

export const validURL = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};
