import React from "react";
import styled from "styled-components";
import { Link } from "components";

const Back = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const LoginLayout = ({ children }) => {
  return (
    <Back>
      {children}
      <div className="d-flex mt-auto mx-auto mb-3">
        <Link href="https://www.deep.ad/privacy-policy" target="_blank">
          Privacy Policy
        </Link>
        <span className="mx-4">-</span>
        <Link href="https://www.deep.ad/tos" target="_blank">
          Terms of Service
        </Link>
      </div>
    </Back>
  );
};
