import React from "react";
import styled from "styled-components";
import LogoImg from "assets/img/logo.png";
import { navigate } from "@reach/router";
import { HEADER_BANNER, HEADER_COLOR } from "config";
import { useAuthContext, usePostContext } from "contexts";
import { IconButton, ProfileModal, PricingModal, UserMode } from "components";
import { useModal } from "hooks";
import { abbreviateName } from "utils";
import { IoMdNotifications } from "react-icons/io";

export const Header = () => {
  const profileModal = useModal();
  const pricingModal = useModal();
  const { userInfo } = useAuthContext();
  const { queryPosts, initPosts } = usePostContext();

  const handleBuy = () => {
    profileModal.onClose();
    pricingModal.openModal();
  };

  const handleNavigateToHome = () => {
    initPosts();
    queryPosts("");
    navigate("/home");
  };

  const handleLogoClick = (event) => {
    if (event.button === 1) {
      initPosts();
      queryPosts("");
      window.open("/home");
    }
  };

  return (
    <StyledHeader>
      <Logo
        src={LogoImg}
        className="my-auto"
        onMouseDown={handleLogoClick}
        onClick={handleNavigateToHome}
        alt="PanelBot"
      />
      {HEADER_BANNER && <Banner>{HEADER_BANNER}</Banner>}
      <div className="d-flex ml-auto">
        <UserMode />
        <IoMdNotifications
          size={28}
          className="my-auto ml-2 mr-2"
          color="white"
        />
        <IconButton
          className="my-auto"
          onClick={() => profileModal.openModal()}
          text={abbreviateName(userInfo?.name)}
          avatar={userInfo?.avatar}
        />
      </div>
      <ProfileModal
        {...profileModal}
        onBuy={handleBuy}
        userName={userInfo?.user_name}
      />
      <PricingModal {...pricingModal} />
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  background: ${HEADER_COLOR};
  height: 50px;
  display: flex;
  padding: 0 24px;
  border-bottom: 4px solid ${(props) => props.theme.palette.canvas};
`;

// const LogoLink = styled.a`
//   margin: auto 0;
//   cursor: pointer;
// `;

const Logo = styled.img`
  height: 30px;
  object-fit: contain;
  cursor: pointer;
`;

const Banner = styled.div`
  color: white;
  font-size: ${(props) => props.theme.font.size.s};
  padding: 20px 10px 10px 10px;
  text-transform: uppercase;
`;
