import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { capitalize } from "lodash";
import { BlueLink } from "./Link";

export const Summary = ({ children, post, isLoading }) => {
  const [summary, setSummary] = useState("");

  useEffect(() => {
    if (post.cert) {
      let str = "";
      if (post?.cert?.meta?.description?.opener) {
        str += capitalize(post?.cert?.meta?.description?.opener || "");
      }
      if (post?.cert?.meta?.stt?.sentence) {
        str += ` ${post?.cert?.meta?.stt?.sentence || ""}`;
      }
      if (post?.cert?.meta?.faces?.tone) {
        str += ` with a ${post?.cert?.meta?.faces?.tone} mood`;
        if (post?.cert?.meta?.sentiment?.mood) {
          str += ` and ${post?.cert?.meta?.sentiment?.mood} tone.`;
        } else {
          str += ".";
        }
      } else if (post?.cert?.meta?.sentiment?.mood) {
        str += ` with a ${post?.cert?.meta?.sentiment?.mood} tone.`;
      }
      if (post?.cert?.meta?.description?.closer) {
        str += ` ${capitalize(post?.cert?.meta?.description?.closer)}`;
      }
      setSummary(capitalize(str.trim()));
    }
    return () => {};
  }, [post]);
  return isLoading ? (
    <Container>
      <SkeletonTheme color="#202020" highlightColor="#444">
        <Skeleton count={3} />
      </SkeletonTheme>
    </Container>
  ) : (
    <Container>
      <div className="mb-3">{summary}</div>
      <div>
        {post?.cert?.meta?.categories &&
          post?.cert?.meta?.categories.filter((a) => a).length > 0 && (
            <div className="d-flex">
              <b>Industry:</b>
              <div className="d-flex flex-wrap">
                {post.cert.meta.categories.map((cat, index) => {
                  return (
                    <BlueLink
                      className="ml-3"
                      href={`/home/?query=${cat}`}
                      key={`${index}key`}
                      target="_blank"
                    >
                      {cat}
                    </BlueLink>
                  );
                })}
              </div>
            </div>
          )}
        {post?.cert?.meta?.category_group && (
          <div>
            <b>Category Group:</b>
            <Value>
              <BlueLink
                className="ml-3"
                href={`/home/?query=${post.cert.meta.category_group}`}
                target="_blank"
              >
                {post.cert.meta.category_group}
              </BlueLink>
            </Value>
          </div>
        )}
        {post?.cert?.meta?.category && (
          <div className="d-flex">
            <b>Category:</b>
            <Value>
              <BlueLink
                className="ml-3"
                href={`/home/?query=${post.cert.meta.category}`}
                target="_blank"
              >
                {post.cert.meta.category}
              </BlueLink>
            </Value>
          </div>
        )}
        {post?.cert?.meta?.sub_category && (
          <div className="d-flex">
            <b>Sub-Category:</b>
            <Value>
              <BlueLink
                className="ml-3"
                href={`/home/?query=${post.cert.meta.sub_category}`}
                target="_blank"
              >
                {post.cert.meta.sub_category}
              </BlueLink>
            </Value>
          </div>
        )}
        {post?.cert?.meta?.big_brand && (
          <div className="d-flex">
            <b>Advertiser:</b>
            <Value>
              <BlueLink
                className="ml-3"
                href={`/home/?query=${post.cert.meta.big_brand}`}
                target="_blank"
              >
                {post.cert.meta.big_brand}
              </BlueLink>
            </Value>
          </div>
        )}
        {post?.cert?.meta?.platform && (
          <div className="d-flex">
            <b>Platform:</b>
            <Value>
              <BlueLink
                className="ml-3"
                href={`/home/?query=${post.cert.meta.platform}`}
                target="_blank"
              >
                {post.cert.meta.platform}
              </BlueLink>
            </Value>
          </div>
        )}
        {post?.cert?.meta?.network && (
          <div className="d-flex">
            <b>Network:</b>
            <Value>
              <BlueLink
                className="ml-3"
                href={`/home/?query=${post.cert.meta.network}`}
                target="_blank"
              >
                {post.cert.meta.network}
              </BlueLink>
            </Value>
          </div>
        )}
        {post?.cert?.meta?.socials && post?.cert?.meta?.socials.length > 0 && (
          <div className="d-flex">
            <b>Socials:</b>
            <div className="d-flex flex-wrap">
              {post.cert.meta.socials.map((social) => (
                <span key={social} className="ml-3">
                  {social}
                </span>
              ))}
            </div>
          </div>
        )}
        {post?.data?.pre?.label && (
          <div className="d-flex">
            <b>Label:</b>
            <Value>
              <a
                className="ml-3"
                href={post?.data?.pre?.label}
                target="_blank"
                rel="noreferrer"
              >
                {post?.data?.pre?.label}
              </a>
            </Value>
          </div>
        )}
        {post?.cert?.meta?.domains && post?.cert?.meta?.domains.length > 0 && (
          <div className="d-flex">
            <b>Domains:</b>
            <div className="d-flex flex-wrap">
              {post.cert.meta.domains.map((social) => (
                <span key={social} className="ml-3">
                  {social}
                </span>
              ))}
            </div>
          </div>
        )}
        {post?.cert?.meta?.urls && post?.cert?.meta?.urls.length > 0 && (
          <div className="d-flex">
            <b>URLs:</b>
            <div className="d-flex flex-wrap">
              {post?.cert?.meta?.urls.map((url, index) => (
                <a
                  key={`${index}key`}
                  href={post?.cert?.meta?.urls}
                  target="_blank"
                  rel="noreferrer"
                  className="ml-3"
                >
                  {post?.cert?.meta?.urls}
                </a>
              ))}
            </div>
          </div>
        )}
        {post?.cert?.meta?.retailer && (
          <div className="d-flex">
            <b>Retailer:</b>
            <Value className="ml-3">{post.cert.meta.retailer}</Value>
          </div>
        )}
        {post?.data?.pre?.hint && (
          <div className="d-flex">
            <b>Banner:</b>
            <Value className="ml-3">{post.data.pre.hint}</Value>
          </div>
        )}
        {post?.data?.pre?.whisper && (
          <div className="d-flex">
            <b>Snippet:</b>
            <Value className="ml-3">{post.data.pre.whisper}</Value>
          </div>
        )}
        {post?.source && post?.source.startsWith("http") && (
          <div className="d-flex">
            <b>Source:</b>
            <Value>
              <a
                className="ml-3"
                href={post.source}
                rel="noreferrer"
                target="_blank"
              >
                {post?.source}
              </a>
            </Value>
          </div>
        )}
        {post?.data?.pre?.landing && (
          <div className="d-flex">
            <b>Landing:</b>
            <Value className="ml-3">{post.data.pre.landing}</Value>
          </div>
        )}
        {post?.data?.pre?.screen && (
          <div className="d-flex">
            <b>Screenshot:</b>
            <Value className="ml-3">{post.data.pre.screen}</Value>
          </div>
        )}
        {post?.data?.pre?.impressions && (
          <div className="d-flex">
            <b>Impressions:</b>
            <Value className="ml-3">{post.data.pre.impressions}</Value>
          </div>
        )}
        {post?.data?.pre?.spend_est && (
          <div className="d-flex">
            <b>Spend:</b>
            <Value className="ml-3">{post.data.pre.spend_est}</Value>
          </div>
        )}
        {post?.cert?.meta?.faces?.genders &&
        post?.cert?.meta?.faces?.genders.length > 0 ? (
          <div className="d-flex">
            <b>People:</b>
            <span className="ml-3">
              {post?.cert?.meta?.faces?.ages?.low &&
                post?.cert?.meta?.faces?.ages?.high &&
                `Age: ${post.cert.meta.faces.ages.low} - ${post.cert.meta.faces.ages.high}`}
            </span>
            <span className="ml-3">
              {post.cert.meta.faces.genders.map((person, index) => (
                <span className="ml-2" key={`${index}key`}>
                  {person}
                </span>
              ))}
            </span>
          </div>
        ) : post?.cert?.meta?.faces?.ages?.low &&
          post?.cert?.meta?.faces?.ages?.high ? (
          <div className="d-flex">
            <b>People:</b>
            <span className="ml-3">
              Age: ${post.cert.meta.faces.ages.low} - $
              {post.cert.meta.faces.ages.high}
            </span>
          </div>
        ) : null}
      </div>
    </Container>
  );
};

const Container = styled.div`
  color: ${(props) => props.theme.palette.white};
  font-size: ${(props) => props.theme.font.size.m};
  margin: 16px 16px 0 16px;
  a {
    color: ${(props) => props.theme.palette.primary};
  }
`;

const Value = styled.div`
  word-break: break-word;
  flex: 1;
`;
