import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, SearchInput } from "components";
import { usePostContext, useAuthContext } from "contexts";
import { parseQuery } from "utils";
import { TAG_PREFIXES } from "config";
import { ReportModal } from "modals";
import { useModal } from "hooks";
import { DURATION_MIN, DURATION_MAX, SPECIAL_DATES } from "config";
import { useLocation, useNavigate, useParams } from "@reach/router";
import { last } from "lodash";
import { qcApproveApi } from "api";

export const SearchBar = ({
  onSearch = () => {},
  onDownload = () => {},
  onUploadURL = () => {},
  onDebug,
  onThumbnail,
  isDebugging,
  isThumbnailing,
  advertiser,
  subCategory,
  post,
}) => {
  const {
    queryStartUploadDate,
    queryEndUploadDate,
    queryStartCreationDate,
    queryEndCreationDate,
    queryTags = [],
    querySearch,
    queryDuration,
    query,
    setQuery,
    setQuerySearch,
    setQueryUploadDates,
    setQueryCreationDates,
    setQueryDuration,
    replaceQueryTags,
    isLoading,
    posts,
  } = usePostContext();
  const reportModal = useModal();
  const { userInfo, userMode } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [prevHash, setPrevHash] = useState("");
  const [nextHash, setNextHash] = useState("");
  const [currentIndex, setcurrentIndex] = useState("");
  const [isApproving, setIsApproving] = useState(false);
  const params = useParams();
  const [qc_ok, setQC_OK] = useState(false);

  useEffect(() => {
    if (location.pathname.split("/")[1] === "post") {
      const currentHash = last(location.pathname.split("/"));
      setPrevHash("");
      setNextHash("");
      if (posts && posts.length > 0) {
        const currentIndex = posts.findIndex(
          (d) => d.post_hash === currentHash
        );
        setcurrentIndex(currentIndex);
        if (currentIndex !== -1) {
          if (currentIndex === posts.length - 1 && posts.length > 1) {
            setNextHash("");
            setPrevHash(posts[currentIndex - 1].post_hash);
          } else if (currentIndex === 0 && posts.length > 1) {
            setPrevHash("");
            setNextHash(posts[currentIndex + 1].post_hash);
          } else if (posts.length > 2) {
            setPrevHash(posts[currentIndex - 1].post_hash);
            setNextHash(posts[currentIndex + 1].post_hash);
          }
        }
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (location.pathname.split("/")[1] === "post" && post?.data) {
      const textMatch = (tag) => /qc_ok/.test(tag.name);
      //console.log("match", post.tags.findIndex(textMatch));
      if (post.tags.findIndex(textMatch) !== -1) {
        //console.log("qc_ok_match");
        setQC_OK(true);
      } else {
        setQC_OK(false);
      }
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post]);

  const handleNext = () => {
    navigate(`/post/${nextHash}`, { replace: true, state: { hash: nextHash } });
  };

  const handlePrev = () => {
    navigate(`/post/${prevHash}`, { replace: true, state: { hash: prevHash } });
  };

  const handleThumbnail = () => {
    onThumbnail();
  };

  const handleDebug = () => {
    onDebug();
  };

  const handleApprove = async () => {
    setIsApproving(true);
    await qcApproveApi({
      target: advertiser,
      class: subCategory,
      post_id: params.id,
    });
    setIsApproving(false);
    if (nextHash !== "") {
      handleNext();
    } else {
      //TODO: We need to push a notification that we are at the end of the queue
    }
  };

  useEffect(() => {
    let tmp = "";
    if (
      (queryStartUploadDate && queryEndUploadDate) ||
      (SPECIAL_DATES.includes(queryStartUploadDate) && !queryEndUploadDate)
    ) {
      tmp += `upload-time:${queryStartUploadDate}..${queryEndUploadDate} `;
    }
    if (
      (queryStartCreationDate && queryEndCreationDate) ||
      (SPECIAL_DATES.includes(queryStartCreationDate) && !queryEndCreationDate)
    ) {
      tmp += `creation-time:${queryStartCreationDate}..${queryEndCreationDate} `;
    }
    if (
      !(
        queryDuration?.min === DURATION_MIN &&
        queryDuration?.max === DURATION_MAX
      )
    ) {
      tmp += `duration:${queryDuration?.min}..${queryDuration?.max} `;
    }
    if (queryTags.length) {
      TAG_PREFIXES.forEach((prefix) => {
        let sub = queryTags.filter((tag) => tag.startsWith(prefix));
        if (sub.length) {
          if (prefix === "target") {
            tmp += `target:${sub.map((a) => a.slice(7)).join(",")} `;
          } else if (prefix === "class") {
            tmp += `class:${sub.map((a) => a.slice(6)).join(",")} `;
          } else {
            tmp += `tag:${sub.join(",")} `;
          }
        }
      });
    }
    tmp = tmp.trimRight();
    if (querySearch) {
      tmp = `${tmp}${querySearch}`;
    }
    setQuery(tmp);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryTags,
    queryStartUploadDate,
    queryEndUploadDate,
    queryStartCreationDate,
    queryEndCreationDate,
    querySearch,
    queryDuration,
  ]);

  const handleChangeSearch = (params) => {
    const { uploadDates, creationDates, tags, search, duration } =
      parseQuery(params);
    // console.log("query", params, parseQuery(params));
    setQuerySearch(search);
    setQueryUploadDates(uploadDates);
    setQueryCreationDates(creationDates);
    setQueryDuration(duration);
    replaceQueryTags(tags);
  };
  const handleDownload = () => {
    reportModal.openModal();
  };

  return (
    <Container>
      <LeftPanel>
        <SearchContainer>
          <SearchInput
            placeholder="Search"
            className="my-auto w-100"
            value={query}
            onChange={handleChangeSearch}
            onEnter={() => onSearch(query)}
            disabled={isLoading}
          />
        </SearchContainer>
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="my-auto ml-3"
          disabled={isLoading}
          onClick={() => onSearch(query)}
        >
          Search
        </Button>
      </LeftPanel>
      <MiddlePanel>
        <Button
          size="size"
          width="fit-content"
          disabled={!query || isLoading}
          className="my-auto"
          onClick={handleDownload}
          data-for="player-tools"
          data-tip="Download reports"
        >
          Download Reports
        </Button>

        {userInfo?.rank === "adm" &&
          location.pathname.split("/")[1] === "post" && (
            <div className="my-auto ml-2 d-flex">
              <Button
                buttonTheme="dark"
                size="small"
                onClick={handleThumbnail}
                className="my-auto"
                disabled={isThumbnailing || isDebugging || isLoading}
                isLoading={isThumbnailing}
                style={{width: 100}}
              >
                Thumbnail
              </Button>

              <Button
                buttonTheme="dark"
                size="small"
                onClick={handleDebug}
                className="my-auto ml-1"
                disabled={isThumbnailing || isDebugging || isLoading}
                isLoading={isDebugging}
                style={{width: 80}}
              >
                Debug
              </Button>

              <Button
                buttonTheme="dark"
                size="small"
                width="90px"
                className="my-auto ml-1"
                disabled={isThumbnailing || isDebugging || isLoading}
                style={{width: 80}}
              >
                Scan
              </Button>
            </div>
          )}
      </MiddlePanel>

      {location.pathname.split("/")[1] === "post" && (
        <RightPanel>
          <LocationRatio>
            {currentIndex + 1}/ {posts.length}
          </LocationRatio>

          <div className="ml-auto d-flex">
            <div className="my-auto ml-2 d-flex">
              <Button
                buttonTheme="dark"
                size="small"
                width="90px"
                className="my-auto"
                disabled={
                  !prevHash || isLoading || isThumbnailing || isDebugging
                }
                onClick={handlePrev}
              >
                Previous
              </Button>
            </div>
            {/* {userInfo.rank === "adm" && (
              <div className="my-auto ml-2 d-flex">
                <Button
                  buttonTheme="dark"
                  size="small"
                  width="90px"
                  className="my-auto"
                  disabled={isLoading || isThumbnailing || isDebugging}
                >
                  Random
                </Button>
              </div>
            )} */}
            <Button
              buttonTheme="dark"
              size="small"
              width="90px"
              className="my-auto ml-2"
              disabled={!nextHash || isLoading || isThumbnailing || isDebugging}
              onClick={handleNext}
            >
              Next
            </Button>
            {userMode !== "normal" && (
              <div className="my-auto ml-2 d-flex">
                <Button
                  buttonTheme={qc_ok ? "success" : "dark"}
                  size="small"
                  className="my-auto"
                  disabled={
                    isLoading ||
                    isThumbnailing ||
                    isDebugging ||
                    !(advertiser && subCategory)
                  }
                  onClick={handleApprove}
                  isLoading={isApproving}
                >
                  {qc_ok ? "Approved" : "Approve"}
                </Button>
              </div>
            )}
          </div>
        </RightPanel>
      )}
      <ReportModal {...reportModal} />
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  height: 50px;
  display: flex;
  padding: 0 24px;
  margin-bottom: 2px;
`;
const LeftPanel = styled.div`
  flex: 1;
  padding-left: 0px;
  padding-right: 24px;
  display: flex;
`;
const MiddlePanel = styled.div`
  border-left: 4px solid ${(props) => props.theme.palette.canvas};
  padding: 0 24px;
  display: flex;
`;
const RightPanel = styled.div`
  border-left: 4px solid ${(props) => props.theme.palette.canvas};
  padding: 0 0 0 24px;
  display: flex;
`;
const LocationRatio = styled.div`
  color: ${(props) => props.theme.palette.white};
  padding: 0 10px 0 0;
  display: flex;
  line-height: 50px;
`;
const SearchContainer = styled.div`
  display: flex;
  flex: 1;
`;
