import React from "react";
import styled from "styled-components";
import { Section } from "./../components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const OfferSection = ({ isLoading, data }) => {
  return isLoading ? (
    <Section title="Offers">
      <SkeletonTheme color="#202020" highlightColor="#444">
        <Skeleton count={3} />
      </SkeletonTheme>
    </Section>
  ) : data?.length > 0 ? (
    <Section title="Offers">
      <div className="m-0">
        <div className="d-flex">
          <Header>Offer Type</Header>
          <Header className="ml-1">Offer Contains</Header>
        </div>
        {data.map((offer, index) => (
          <div className="d-flex" key={`${index}key`}>
            <Body>{offer.offer_type}</Body>
            <Body className="ml-1">{offer.value}</Body>
          </div>
        ))}
      </div>
    </Section>
  ) : null;
};
const Header = styled.div`
  background: ${(props) => props.theme.palette.items};
  color: white;
  font-size: 16px;
  line-height: 28px;
  padding: 4px 24px;
  flex: 1;
`;
const Body = styled.div`
  background: ${(props) => props.theme.palette.gray};
  color: white;
  font-size: 12x;
  padding: 6px 24px;
  flex: 1;
  border-bottom: 1px solid ${(props) => props.theme.palette.items};
`;
