/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useReducer, useEffect } from "react";
import { useLocalstorageState } from "rooks";
import AuthReducer from "./AuthReducer";
import { navigate } from "@reach/router";
import { TOKEN_EXPIRATION } from "config";
import { getUserInfoApi, getAppInfoApi } from "api";
import { clearAllCookies } from "utils";
// import { isEqual } from "lodash";
import {
  LOGIN,
  LOGOUT,
  GET_USER_INFO,
  SET_USER_MODE,
  GET_APP_INFO,
} from "./../types";
export const authInitState = {
  isLogined: false,
  userInfo: {},
};

export const AuthContext = createContext(authInitState);
export const useAuthContext = () => useContext(AuthContext);
const STORAGE_KEY = "user-state";

export const AuthProvider = ({ children }) => {
  const initialState = {
    isLogined: false,
    userMode: "normal",
    userInfo: {},
    appInfo: {},
  };
  const [value, set] = useLocalstorageState(STORAGE_KEY, initialState);
  const [state, dispatch] = useReducer(AuthReducer, value);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();

      if (
        localStorage.getItem("panelbot_token_expire") &&
        now.getTime() > parseInt(localStorage.getItem("panelbot_token_expire"))
      ) {
        logoutUser(true);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    set(state);
    return () => {};
  }, [state]);

  const loginUser = async () => {
    const now = new Date();
    localStorage.setItem(
      "panelbot_token_expire",
      now.getTime() + TOKEN_EXPIRATION
    );
    dispatch({ type: LOGIN });
  };

  const logoutUser = (isExpire) => {
    dispatch({ type: LOGOUT });
    localStorage.removeItem("user");
    localStorage.removeItem("panelbot_token_expire");
    clearAllCookies();
    navigate(`/${isExpire ? "?token_expire=true" : ""}`);
  };

  const getUserInfo = async () => {
    const res = await getUserInfoApi();
    dispatch({ type: GET_USER_INFO, payload: res.payload });
  };

  const getAppInfo = async () => {
    const res = await getAppInfoApi();
    dispatch({ type: GET_APP_INFO, payload: res });
  };

  const setUserMode = (payload) => {
    dispatch({
      type: SET_USER_MODE,
      payload,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loginUser,
        logoutUser,
        getUserInfo,
        setUserMode,
        getAppInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
