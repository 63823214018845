/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import styled from "styled-components";
import { usePostContext } from "contexts";
import { PlayerSeekbar } from "components";
import {
  Player,
  ControlBar,
  CurrentTimeDisplay,
  TimeDivider,
  LoadingSpinner,
  PlayToggle,
  VolumeMenuButton,
  Shortcut,
  SeekBar,
  ProgressControl,
  DurationDisplay,
  FullscreenToggle,
} from "video-react";
import { FaPlay, FaPause } from "react-icons/fa";
import { convertString2Time } from "utils/time";
import useInterval from "use-interval";

import "./style.scss";

export const VideoPlayer = forwardRef(
  (
    {
      onZoom = () => {},
      onMove = () => {},
      creditSize,
      isLoading,
      zoomRatio,
      cornerX,
      cornerY,
      srcUrl,
      startTime,
      visible,
    },
    ref
  ) => {
    const { post } = usePostContext();
    const [token, setToken] = useState("");
    const containerRef = useRef();
    const player = useRef();
    const [isPaused, setIsPaused] = useState(true);
    const [innerCurrentTime, setInnerCurrentTime] = useState(startTime);
    const [duration, setDuration] = useState(0);
    useEffect(() => {
      setToken(localStorage.getItem("user"));
      return () => {};
    }, []);

    useImperativeHandle(ref, () => ({
      pause() {
        player.current && player.current.pause();
        return player.current.getState().player.currentTime;
      },
    }));

    const handleMouseWheel = (e) => {
      onZoom(e.deltaY < 0 ? zoomRatio * 1.1 : zoomRatio * 0.9);
    };

    useInterval(() => {
      if (player.current) {
        setInnerCurrentTime(player.current.getState().player.currentTime);
        setIsPaused(player.current.getState().player.paused);
        setDuration(player.current.getState().player.duration);
      }
    }, 900);

    useEffect(() => {
      let isDown = false;
      let lastPosX = 0;
      let lastPosY = 0;
      const handleMouseDown = (e) => {
        lastPosX = e.clientX;
        lastPosY = e.clientY;
        if (e.altKey) {
          isDown = true;
        }
      };
      const handleMouseUp = (e) => {
        isDown = false;
      };
      const handleMouseMove = (e) => {
        if (isDown) {
          onMove(e.clientX - lastPosX, e.clientY - lastPosY);
          lastPosX = e.clientX;
          lastPosY = e.clientY;
        }
      };

      containerRef.current &&
        containerRef.current.addEventListener("mousedown", handleMouseDown);
      containerRef.current &&
        containerRef.current.addEventListener("mousemove", handleMouseMove);
      containerRef.current &&
        containerRef.current.addEventListener("mouseup", handleMouseUp);

      return () => {
        containerRef.current &&
          containerRef.current.removeEventListener(
            "mousedown",
            handleMouseDown
          );
        containerRef.current &&
          containerRef.current.removeEventListener(
            "mousemove",
            handleMouseMove
          );
        containerRef.current &&
          containerRef.current.removeEventListener("mouseup", handleMouseUp);
      };
    }, [containerRef.current]);

    const handleClickPlay = () => {
      const {
        player: { paused },
      } = player.current.getState();
      if (paused) {
        player.current.play();
      } else {
        player.current.pause();
      }
      setIsPaused(!paused);
    };

    const handleSeek = (params) => {
      player.current.seek(params);
    };

    return token ? (
      <Container
        ref={containerRef}
        onWheel={handleMouseWheel}
        zoom={zoomRatio}
        x={cornerX}
        y={cornerY}
      >
        <Player
          ref={player}
          src={srcUrl}
          disableDefaultControls={post && post.credit_size === 1}
          disableCompletely={post && post.credit_size === 1}
          crossOrigin={"anonymous"}
          controls={false}
          preload="auto"
          startTime={startTime}
        >
          <Shortcut clickable={false} />
          <ControlBar
            autoHide={false}
            disableCompletely={post && post.credit_size === 1}
            disableDefaultControls={post && post.credit_size === 1}
          >
            <PlayToggle disabled />
            <LoadingSpinner disabled />
            <CurrentTimeDisplay order={4.1} disabled />
            <TimeDivider order={4.2} disabled />
            <DurationDisplay order={4.2} disabled />
            <VolumeMenuButton disabled />
            <SeekBar disabled />
            <ProgressControl disabled />
            <FullscreenToggle disabled />
          </ControlBar>
        </Player>
        <Toolbar>
          <PlayButton className="my-auto" onClick={handleClickPlay}>
            {isPaused ? <FaPlay color="white" /> : <FaPause color="white" />}
          </PlayButton>
          <Time className="my-auto ml-2">
            {convertString2Time(innerCurrentTime)}&nbsp;/&nbsp;{" "}
            {convertString2Time(duration)}
          </Time>
          <PlayerSeekbar
            className="my-auto ml-3 mb-1"
            duration={duration}
            value={innerCurrentTime}
            onChange={handleSeek}
          />
        </Toolbar>
      </Container>
    ) : null;
  }
);

const Container = styled.div`
  flex: 1;
  position: relative;
  .video-react {
    overflow: hidden;
  }
  video {
    transform: ${(props) =>
      `scale(${props.zoom}) translate(${props.x}px, ${props.y}px)`};
  }
`;

const Toolbar = styled.div`
  display: flex;
  min-height: 48px;
  height: 48px;
  padding: 20px 8px 0 8px;
`;

const PlayButton = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  cursor: pointer;
  transition: all 0.5s ease;
  svg {
    margin: auto;
  }
  &:hover {
    opacity: 0.5;
  }
`;

const Time = styled.div`
  width: 100px;
  min-width: 100px;
  text-align: center;
`;
