import React, { useEffect, useState } from "react";
import { times } from "lodash";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { ScrollView, Button } from "components";
import { isBefore } from "date-fns";
import { useUploadContext, usePostContext } from "contexts";
import { TableRow } from "./TableRow";
import UploadImg from "assets/img/cloud-upload.png";
import RiseLoader from "react-spinners/RiseLoader";
import { css as EmotionCSS } from "@emotion/react";

export const TableContent = ({
  isLoading,
  data,
  sortCol,
  sortType,
  onUpload,
}) => {
  const { setUploadMinimized } = useUploadContext();
  const { fetchMorePosts, isFetching, remainingPosts, pinData, setPinData } =
    usePostContext();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDragOver: (event) => handleDragOver(event),
    onDropAccepted: (event) => myCustomFileGetter(event),
  });
  const [innerData, setInnerData] = useState([]);

  const handleDragOver = () => {
    setUploadMinimized(true);
  };

  async function myCustomFileGetter(event) {
    const files = [];

    for (var i = 0; i < event.length; i++) {
      const file = event[i];
      Object.defineProperty(file, "myProp", {
        value: true,
      });
      files.push(file);
    }
    onUpload(files);
    setUploadMinimized(false);
  }

  useEffect(() => {
    let temp = [
      ...data.map((row) => {
        return { ...row, pin: false };
      }),
    ];
    temp.sort((a, b) => {
      if (a[sortCol] && b[sortCol]) {
        if (sortCol === "creation_time") {
          return sortType === 2 &&
            isBefore(new Date(a["creation_time"]), new Date(b["creation_time"]))
            ? 1
            : -1;
        } else if (sortCol === "title" || sortCol === "status") {
          return sortType === 2
            ? a[sortCol].localeCompare(b[sortCol])
            : !a[sortCol].localeCompare(b[sortCol]);
        } else {
          return sortType === 2
            ? a[sortCol] - b[sortCol]
            : b[sortCol] - a[sortCol];
        }
      }
      return 0;
    });

    (pinData ?? []).forEach((item) => {
      let pinned = temp.find(({ post_hash }) => post_hash === item);
      if (pinned) {
        pinned = { ...pinned, pin: true };
        temp = [pinned, ...temp.filter(({ post_hash }) => post_hash !== item)];
      }
    });
    setInnerData(temp);
    return () => {};
  }, [data, sortType, sortCol, pinData]);

  const handlePin = (params) => {
    if (pinData.includes(params)) {
      setPinData(pinData.filter((a) => a !== params));
    } else {
      setPinData([...pinData, params]);
    }
  };

  return (
    <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
      <input {...getInputProps()} />

      {isLoading ? (
        times(7, String).map((index) => (
          <TableRow key={`${index}key`} isLoading />
        ))
      ) : (
        <>
          {innerData.map((item, index) => (
            <TableRow {...item} key={`${index}key`} onPin={handlePin} />
          ))}
          {remainingPosts > 0 && innerData.length > 0 && (
            <div className="d-flex my-4">
              {isFetching ? (
                <RiseLoader
                  size={20}
                  margin={0}
                  css={EmotionCSS`
                            display: flex;
                            margin: 40px auto;
                          `}
                  color="#006280"
                />
              ) : (
                <Button
                  className="mx-auto"
                  size="small"
                  width={"160px"}
                  onClick={() => fetchMorePosts()}
                >
                  LOAD MORE
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled(ScrollView)`
  position: relative;
  transition: all 300ms ease;
  border: ${(props) =>
    props.isDragAccept || props.isDragActive
      ? `1px dashed ${props.theme.palette.primary}`
      : "1px solid transparent"};
  &::after {
    display: ${(props) =>
      props.isDragAccept || props.isDragActive ? "block" : "none"};
    content: " ";
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background: #66c0e120 url(${UploadImg}) no-repeat center;
    background-size: 240px 160px;
    /* opacity: 0.2; */
  }
`;
