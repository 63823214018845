import { API } from "utils";

export const uploadFiles = async (files) => {
  try {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    const response = await API().post("api/file-upload", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: e };
  }
};

export const uploadURLs = async (params) => {
  try {
    const formData = new FormData();
    formData.append("search", params);
    const response = await API().post("api/url-upload", formData);
    return response.data;
  } catch (e) {
    return { success: false, payload: e };
  }
};
