import { API } from "utils";
import { last } from "lodash";

export const getAdvertisers = async (search = "", user_id) => {
  try {
    const response = await API().get(
      `api/get_advertisers?search=${search}&post_id=${last(
        window.location.pathname.split("/")
      )}${user_id ? `&user_id=${user_id}` : ""}`
    );
    return response.data;
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};

export const getSubCategories = async (search = "", user_id) => {
  try {
    const response = await API().get(
      `api/get_sub_categories?search=${search}${
        user_id ? `&user_id=${user_id}` : ""
      }`
    );
    return response.data;
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};

export const updateCertMeta = async (cert_id, meta) => {
  try {
    const response = await API().post(`api/update_cert_meta`, {
      cert_id,
      meta,
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};

export const updateCertMetaField = async (cert_id, field, value) => {
  try {
    const response = await API().post(`api/update_cert_meta_field`, {
      cert_id,
      field,
      value,
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};
