import React from "react";
import styled from "styled-components";

export const MetaValue = ({ label, value, className }) => {
  return (
    <div className={className}>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </div>
  );
};

const Label = styled.div`
  color: ${(props) => props.theme.palette.secondary};
  font-size: ${(props) => props.theme.font.size.s};
`;

const Value = styled.div`
  margin-top: 4px;
  background: ${(props) => props.theme.palette.items};
  color: ${(props) => props.theme.palette.white};
  font-size: ${(props) => props.theme.font.size.m};
  height: ${(props) => props.theme.font.size.xl};
  line-height: ${(props) => props.theme.font.size.xl};
  padding: 0 14px;
`;
