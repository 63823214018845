import React from "react";
import styled from "styled-components";

export const Input = ({ className, label, value, onChange = () => {} }) => {
  return (
    <Container className={className}>
      {label && <Label htmlFor={label}>{label}</Label>}
      <StyledInput
        id={label}
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
      />
    </Container>
  );
};
const Container = styled.div`
  position: relative;
`;
const Label = styled.label`
  font-size: 15px;
  color: ${(props) => props.theme.palette.white};
  display: flex;
`;
const StyledInput = styled.input`
  border: 1px solid ${(props) => props.theme.palette.secondary};
  background: ${(props) => props.theme.palette.gray};
  color: ${(props) => props.theme.palette.white};
  height: 42px;
  width: 100%;
  font-size: ${(props) => props.theme.font.size.m};
  outline: none;
  padding: 12px;
  transition: all 300ms ease;
  &:focus,
  &:active {
    border: 1px solid ${(props) => props.theme.palette.primaryHover};
  }
`;
