import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import HtmlParser from "react-html-parser";
import { Text } from "./../components";

export const TextVisual = ({ isLoading, post, data = [] }) => {
  const [text, setText] = useState("");

  useEffect(() => {
    if (post) {
      let tmp = "";
      if (post?.cert?.meta?.strict) {
        tmp += ` ${post?.cert?.meta?.strict || ""}`;
      } else {
        tmp += ` ${post?.cert?.meta?.clean || ""}`;
      }
      if (post?.cert?.meta?.lang?.guess !== "eng" && post.cert?.meta?.english) {
        tmp += " Translated: <br/>";
        tmp += post.cert?.meta?.english;
      }
      setText(tmp);
    }
    return () => {};
  }, [post]);

  return isLoading ? (
    <Container>
      <Header>VISUAL</Header>
      <div className="mt-2">
        <SkeletonTheme color="#202020" highlightColor="#444">
          <Skeleton count={2} />
        </SkeletonTheme>
      </div>
    </Container>
  ) : (
    <Container>
      <Header>VISUAL</Header>
      <div className="d-flex mt-2 flex-column">
        <Texts>{HtmlParser(text)}</Texts>
        {data.map((text, index) => (
          <Text index={index + 1} name={text} key={`${index}key`} />
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 240px;
  flex: 1;
  margin-right: 20px;
`;

const Header = styled.div`
  font-size: 16px;
  color: white;
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
`;

const Texts = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
  word-break: break-word;
`;
