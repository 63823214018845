import React from "react";
import { Button, Alert } from "components";
import styled from "styled-components";

export const UploadInvalidFormatModal = ({ onRemove, file, ...props }) => {
  return (
    <Alert
      color={"error"}
      size="small"
      title="Error: Invalid File Format"
      {...props}
    >
      <Content>
        <div>This file format is not supported.</div>
        <SeeBelow className="mt-2 mb-1">See below for valid formats.</SeeBelow>
        <Title>AUDIO</Title>
        <Body>
          mp3, wav, aif, m3u, wav, ogg, vorb, aac, wma, 3gp, aa, aax, flac
        </Body>
        <Title>IMAGE</Title>
        <Body>png, jpeg, jpg, bmp, gif, tiff, raw, aeif, heic</Body>
        <Title>VIDEO</Title>
        <Body>
          mp4, mpg, mkv, mpegts, ts, apng, gif, webp, heif, webm, flv, gifv,
          avi, wmv, asf, m4p,
        </Body>
        <Title>DATA</Title>
        <Body>pdf, eps, ps</Body>
        <Title>FILE</Title>
        <Body>
          csv, tsv, tab, ods, xls, xlsx, txt, json, xml, rtf, doc, docx
        </Body>
      </Content>
      <div className="mt-4 d-flex">
        <Button
          className="ml-auto"
          size="small"
          width={400}
          buttonTheme="dark"
          onClick={() => {
            props.onClose();
            onRemove(file);
          }}
        >
          Remove Upload
        </Button>
      </div>
    </Alert>
  );
};

const SeeBelow = styled.div`
  font-size: 14px;
`;

const Content = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  border-bottom: 1px solid #2b2b2b;
`;
const Body = styled.div`
  font-size: 12px;
  padding: 6px 12px;
`;
