import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button } from "components";
import { css } from "@emotion/react";
import { API_URL } from "config";
import ClipLoader from "react-spinners/ClipLoader";
import probe from "probe-image-size";

export const CapturePane = ({
  onCapture,
  onClose,
  enableCapture,
  playerRef,
  playerContainerRef,
  postId,
  creditSize,
}) => {
  const canvas = useRef();
  const [startPos, setStartPos] = useState({});
  const [endPos, setEndPos] = useState({});
  const [isDown, setIsDown] = useState(false);
  const [isUp, setIsUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState("");
  const [videoRegion, setVideoRegion] = useState({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  });
  const [imageSize, setImageSize] = useState({});

  let ctx = null;

  const getImageSize = async () => {
    const size = await probe(`${API_URL}/api/thumb/${postId}?jwt=${token}`);
    console.log("credit size", creditSize, token, size);
    setImageSize(size);
  };

  useEffect(() => {
    if (creditSize === 1 && token) {
      getImageSize();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditSize, token]);

  useEffect(() => {
    setToken(localStorage.getItem("user"));
    document.addEventListener("keydown", handleKeyPress, false);
    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (enableCapture) {
      init();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableCapture]);

  const init = async () => {
    setStartPos({ x: 0, y: 0 });
    setEndPos({ x: 0, y: 0 });
    setIsDown(false);
    setIsUp(false);
    setIsLoading(false);
    clearBackground();

    let videoRect = playerContainerRef.current.getBoundingClientRect();

    let centerX = (videoRect.left + videoRect.right) / 2;
    let centerY =
      creditSize === 1
        ? (videoRect.top + videoRect.bottom) / 2
        : (videoRect.top + videoRect.bottom - 32) / 2;
    let width = videoRect.right - videoRect.left;
    let height =
      creditSize === 1
        ? videoRect.bottom - videoRect.top
        : videoRect.bottom - videoRect.top - 32;

    let videoRatio = 0;
    if (playerRef.current && creditSize !== 1) {
      const {
        player: { videoWidth, videoHeight },
      } = playerRef.current.getState();
      videoRatio = videoHeight / videoWidth;
      console.log("video ratio", videoRatio);
    } else {
      videoRatio = imageSize.height / imageSize.width;
      console.log("video ratio 2", imageSize, videoRatio);
    }
    let realVideoWidth, realVideoHeight;
    if (videoRatio * width > height) {
      realVideoWidth = height / videoRatio;
      realVideoHeight = realVideoWidth * videoRatio;
    } else {
      realVideoWidth = width;
      realVideoHeight = width * videoRatio;
    }
    const realVideoRect = {
      top: centerY - realVideoHeight / 2,
      bottom: centerY + realVideoHeight / 2,
      left: centerX - realVideoWidth / 2,
      right: centerX + realVideoWidth / 2,
    };
    setVideoRegion(realVideoRect);
  };
  const isInVideoRegion = (x, y) => {
    return (
      x >= videoRegion.left &&
      x <= videoRegion.right &&
      y >= videoRegion.top &&
      y <= videoRegion.bottom
    );
  };
  const clearBackground = () => {
    // dynamically assign the width and height to canvas
    const canvasEle = canvas.current;
    canvasEle.width = canvasEle.clientWidth;
    canvasEle.height = canvasEle.clientHeight;

    // get context of the canvas
    ctx = canvasEle.getContext("2d");
    ctx.beginPath();
    ctx.strokeStyle = "black";
    ctx.lineWidth = 1;
    ctx.fillStyle = "rgba(0,0,0,0.5)";
    ctx.fillRect(0, 0, canvasEle.width, canvasEle.height);
  };

  const handleMouseDown = (e) => {
    if (!isLoading) {
      const startX = parseInt(
        e.nativeEvent.offsetX - canvas.current.clientLeft
      );
      const startY = parseInt(e.nativeEvent.offsetY - canvas.current.clientTop);
      if (isInVideoRegion(startX, startY)) {
        setIsDown(true);
        setStartPos({ x: startX, y: startY });
        setEndPos({ x: startX, y: startY });
      }
    }
  };
  const handleMouseMove = (e) => {
    if (!isDown) return;

    const mouseX = parseInt(e.nativeEvent.offsetX - canvas.current.clientLeft);
    const mouseY = parseInt(e.nativeEvent.offsetY - canvas.current.clientTop);
    setEndPos({
      x:
        mouseX < videoRegion.left
          ? videoRegion.left
          : mouseX > videoRegion.right
          ? videoRegion.right
          : mouseX,
      y:
        mouseY < videoRegion.top
          ? videoRegion.top
          : mouseY > videoRegion.bottom
          ? videoRegion.bottom
          : mouseY,
    });
  };
  const handleMouseUp = (e) => {
    if (isDown) {
      setIsDown(false);
      setIsUp(true);
    }
  };

  const handleMouseOut = (e) => {
    handleMouseUp(e);
  };

  useEffect(() => {
    if (!isLoading && isDown && canvas.current) {
      const canvasEle = canvas.current;
      canvasEle.width = canvasEle.clientWidth;
      canvasEle.height = canvasEle.clientHeight;

      const topLeftX = Math.min(startPos.x, endPos.x);
      const topLeftY = Math.min(startPos.y, endPos.y);
      const bottomRightX = Math.max(startPos.x, endPos.x);
      const bottomRightY = Math.max(startPos.y, endPos.y);
      // get context of the canvas
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ctx = canvasEle.getContext("2d");
      ctx.clearRect(0, 0, canvasEle.width, canvasEle.height);
      ctx.beginPath();
      ctx.strokeStyle = "black";
      ctx.lineWidth = 1;
      ctx.fillStyle = "rgba(0,0,0,0.5)";
      ctx.fillRect(0, 0, canvasEle.width, topLeftY);
      ctx.fillRect(0, topLeftY, topLeftX, bottomRightY - topLeftY);
      ctx.fillRect(
        0,
        bottomRightY,
        canvasEle.width,
        canvasEle.height - bottomRightY
      );
      ctx.fillRect(
        bottomRightX,
        topLeftY,
        canvasEle.width - bottomRightX,
        bottomRightY - topLeftY
      );
      ctx.stroke();
      ctx.strokeStyle = "#ff8484";
      ctx.setLineDash([15, 5]);
      ctx.strokeRect(
        startPos.x,
        startPos.y,
        endPos.x - startPos.x,
        endPos.y - startPos.y
      );
    }
    return () => {};
  }, [startPos, endPos, ctx, isDown]);

  const handleCapture = async () => {
    let frame = -1;
    if (creditSize !== 1) {
      const {
        player: { currentTime },
      } = playerRef.current.getState();
      frame = currentTime === 0 ? -1 : currentTime;
    }

    onCapture({
      tl: `${(
        (startPos.x - videoRegion.left) /
        (videoRegion.right - videoRegion.left)
      ).toFixed(5)},${(
        (startPos.y - videoRegion.top) /
        (videoRegion.bottom - videoRegion.top)
      ).toFixed(5)}`,
      br: `${(
        (endPos.x - videoRegion.left) /
        (videoRegion.right - videoRegion.left)
      ).toFixed(5)},${(
        (endPos.y - videoRegion.top) /
        (videoRegion.bottom - videoRegion.top)
      ).toFixed(5)}`,
      frame,
    });
  };

  const handleKeyPress = (event) => {
    switch (event.keyCode) {
      case 27:
        onClose();
        break;
      case 13:
        if (!isDown && isUp) {
          handleCapture();
        }
        break;
      default:
        break;
    }
  };

  const cancelCapture = () => {
    onClose();
  };

  return enableCapture ? (
    <>
      <Container
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseOut={handleMouseOut}
        onKeyPress={handleKeyPress}
        ref={canvas}
      ></Container>

      {isLoading ? (
        <LoadingContainer
          x={(endPos.x + startPos.x) / 2 - 40}
          y={(endPos.y + startPos.y) / 2 - 40}
        >
          <ClipLoader
            color={"#006280"}
            css={css`
              margin: auto;
            `}
            size={80}
          />
        </LoadingContainer>
      ) : (
        !isDown &&
        isUp && (
          <div className="d-flex">
            <ButtonContainer
              x={(endPos.x + startPos.x) / 2 - 90}
              y={Math.max(endPos.y, startPos.y) + 10}
            >
              <Button size="small" width="80px" onClick={handleCapture}>
                OK
              </Button>
            </ButtonContainer>
            <ButtonContainer
              x={(endPos.x + startPos.x) / 2 + 10}
              y={Math.max(endPos.y, startPos.y) + 10}
            >
              <Button
                size="small"
                buttonTheme="dark"
                width="80px"
                onClick={cancelCapture}
              >
                Cancel
              </Button>
            </ButtonContainer>
          </div>
        )
      )}
    </>
  ) : null;
};

const Container = styled.canvas`
  position: fixed;
  width: 100vw;
  height: 100vh;
`;

const ButtonContainer = styled.div`
  position: fixed;
  left: ${(props) => `${props.x}px`};
  top: ${(props) => `${props.y}px`};
  z-index: 99;
`;

const LoadingContainer = styled.div`
  position: fixed;
  left: ${(props) => `${props.x}px`};
  top: ${(props) => `${props.y}px`};
  z-index: 99;
`;
