import React, { useEffect, useState } from "react";
import styled from "styled-components";

export const Celebrity = ({ data, img, className }) => {
  const [description, setDescription] = useState("");
  useEffect(() => {
    if (data) {
      setDescription(data.extract);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <Container className={className}>
      <Heading>{data?.feature}</Heading>
      <div className="mt-1">{description}</div>
      {/* <ReactTooltip
        id="brand-desc"
        className="brand-tooltip"
        place="top"
        multiline
        effect="solid"
        brand-tooltip="#000"
      /> */}
    </Container>
  );
};

const Container = styled.div`
  font-size: 14px;
  margin: 4px 0;
`;

const Heading = styled.div`
  color: white;
  font-size: 18px;
  text-decoration: underline;
`;
