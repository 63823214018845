import React from "react";
import { Modal, Button } from "components";

export const PaymentStatusModal = ({ open, onClose, status }) => {
  return (
    <Modal open={open} size="small" onClose={onClose} title="">
      <div className="my-4">
        <h3 className="text-center">
          {status === "success"
            ? " Payment is successfully processed!"
            : " Payment is canceled!"}
        </h3>
      </div>
      <div className="d-flex">
        <Button
          size="large"
          width="160px"
          buttonTheme="primary"
          className="mt-4 mx-auto"
          onClick={() => onClose()}
        >
          Ok
        </Button>
      </div>
    </Modal>
  );
};
