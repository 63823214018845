import React from "react";
import { Button, Alert } from "components";

export const UploadFailedModal = ({ onRetry, onRemove, file, ...props }) => {
  return (
    <Alert color={"error"} size="small" title="Error: Upload Failed" {...props}>
      <div>Asset failed to upload, please retry the asset.</div>
      <div className="mt-4 d-flex">
        <Button
          className="ml-auto"
          size="small"
          buttonTheme="dark"
          width={400}
          onClick={() => {
            props.onClose();
            onRetry(file);
          }}
        >
          Retry Upload
        </Button>
        <Button
          className="ml-3"
          size="small"
          buttonTheme="dark"
          width={400}
          onClick={() => {
            props.onClose();
            onRemove(file);
          }}
        >
          Remove Upload
        </Button>
      </div>
    </Alert>
  );
};
