import React from "react";
import styled from "styled-components";
import { RiErrorWarningFill } from "react-icons/ri";
export const Warning = () => {
  return (
    <Container>
      <RiErrorWarningFill size={40} />
      <div className="ml-3 d-flex flex-column">
        <div className="title mt-auto">WARNING</div>
        <div className="mb-auto">
          This record is not complete and should be reviewed. Conditions: Extra
          Numbers, Multiple Offers, Unmapped Price
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  background: ${(props) => props.theme.palette.warning};
  color: ${(props) => props.theme.palette.items};
  font-size: ${(props) => props.theme.font.size.m};
  height: 90px;
  padding: 0 16px;
  .title {
    font-size: ${(props) => props.theme.font.size.xm};
    font-weight: ${(props) => props.theme.font.weight.l};
  }
  svg {
    color: ${(props) => props.theme.palette.gray};
    margin: auto 0;
  }
`;
