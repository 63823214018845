import React, { useState, useRef } from "react";
import { AiFillSetting } from "react-icons/ai";
import styled from "styled-components";
import { useOutsideClick } from "hooks";

const data = [{ label: "Pin a Field", value: "pin" }];

export const SettingMenu = ({ onClick = () => {}, className }) => {
  const menuRef = useRef();
  const [isMenuShown, setIsMenuShown] = useState(false);
  const handleClick = () => {
    setIsMenuShown((state) => !state);
  };
  function clickOutsideMenu() {
    setIsMenuShown(false);
  }
  useOutsideClick(menuRef, clickOutsideMenu);

  return (
    <Container className={className}>
      <MenuIcon onClick={handleClick}>
        <AiFillSetting color="white" size={24} />
      </MenuIcon>
      {isMenuShown && (
        <MenuContent ref={menuRef}>
          {data.map(({ label, value }) => (
            <MenuItem
              onClick={() => {
                onClick(value);
                setIsMenuShown(false);
              }}
              key={value}
            >
              {label}
            </MenuItem>
          ))}
        </MenuContent>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const MenuIcon = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.7;
  }
`;

const MenuContent = styled.div`
  box-shadow: 0 4px 2px rgba(0, 0, 0, 0.16);
  background: ${(props) => props.theme.palette.items};
  padding: 12px;
  position: absolute;
  width: 110px;
  right: 0;
  margin-top: 4px;
`;

const MenuItem = styled.div`
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }
`;
