import React, { useEffect, useState } from "react";
import { usePostContext } from "contexts";
import styled from "styled-components";
import ReactHTMLParser from "react-html-parser";

export const Brand = ({ data, className }) => {
  const { post } = usePostContext();
  const [description, setDescription] = useState("");
  useEffect(() => {
    if (data) {
      const brand = data;
      let desc = "";
      if (brand.detected) {
        desc += "This ";
        if (brand.description) {
          desc += ` ${brand.description.toLowerCase()}`;
        }
        if (brand.detected === "logo") {
          desc += `brand was detected via its logo `;
          if (brand.qpp) {
            desc += ` in ${(brand.qpp * 100).toFixed(1)}% of speech. <br/> `;
          } else if (brand.ppp) {
            desc += ` in ${(brand.ppp * 100).toFixed(1)}% of pixels. <br/>`;
          } else if (brand.lpp) {
            desc += ` in ${(brand.lpp * 100).toFixed(1)}% of text. <br/>`;
          }
        } else if (brand.detected === "product") {
          desc +=
            "brand was detected from the featured product information. <br/> ";
        } else {
          desc += `brand was detected in the ${post?.medium?.toLowerCase()} ${
            brand.detected
          } `;
          if (brand.qpp) {
            desc += ` in ${(brand.qpp * 100).toFixed(1)}% of speech. <br/>`;
          } else if (brand.ppp) {
            desc += ` in ${(brand.ppp * 100).toFixed(1)}% of pixels. <br/>`;
          } else if (brand.lpp) {
            desc += ` in ${(brand.lpp * 100).toFixed(1)}% of text. <br/>`;
          }
        }
      }
      if (brand.extract) {
        desc += brand.extract + "<br/>";
      }
      if (brand.trademark && brand.trademark.company) {
        desc += ` This copyright is held by ${brand.trademark.company}`;
      }
      setDescription(desc);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <Container className={className}>
      <Heading>{data?.brand}</Heading>
      <div className="mt-1">{ReactHTMLParser(description)}</div>
    </Container>
  );
};

const Container = styled.div`
  font-size: 14px;
  margin: 4px 0;
`;
const Heading = styled.div`
  color: white;
  font-size: 18px;
  text-decoration: underline;
`;
