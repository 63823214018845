import React, { useState, useEffect } from "react";
import {
  BrandSection,
  ProductSection,
  OfferSection,
  CelebritiesSection,
  SummarySection,
  TextSection,
} from "./../fragments";

export const Information = ({
  post,
  summary,
  metaData,
  isLoading,
  onUpdate = () => {},
  onSetValue = () => {},
}) => {
  const [texts, setTexts] = useState([]);

  useEffect(() => {
    if (post?.cert) {
      if (
        post?.cert?.meta?.super_brands &&
        post?.cert?.meta?.super_brands.length > 0
      ) {
        let temp = [];
        if (post?.cert?.meta?.ultra_texts) {
          Object.keys(post?.cert?.meta?.ultra_texts).forEach((key) => {
            temp.push(post?.cert?.meta?.ultra_texts[key].simple);
          });
          setTexts(temp);
        }
        setTexts(temp);
      }
    }
    return () => {};
  }, [post]);

  return (
    <div className="d-flex flex-column">
      <SummarySection
        post={post}
        isLoading={isLoading}
        summary={summary}
        metaData={metaData}
        onUpdate={onUpdate}
        onSetValue={onSetValue}
      />
      <BrandSection
        data={post?.cert?.meta?.super_brands || []}
        isLoading={isLoading}
      />
      <CelebritiesSection
        data={post?.cert?.meta?.super_celebs || []}
        isLoading={isLoading}
      />
      <OfferSection
        isLoading={isLoading}
        data={post?.cert?.meta?.super_offers || []}
      />
      <ProductSection
        isLoading={isLoading}
        data={post?.cert?.meta?.super_products || []}
      />
      <TextSection isLoading={isLoading} post={post} texts={texts} />
    </div>
  );
};
