import React from "react";
import styled from "styled-components";

export const Web = ({ name, img, url }) => {
  return (
    <Container href={img} target="_blank">
      <Pic src={img} />

      <Name>{name}</Name>
    </Container>
  );
};

const Container = styled.a`
  width: 180px;
  margin: 20px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.palette.primary};
  }
`;
const Pic = styled.img`
  width: 180px;
  height: 180px;
  position: relative;
  background: #0a0a0a;
  object-fit: contain;
`;
const Name = styled.div`
  margin-top: 10px;
  color: white;
  font-size: 14px;
  transition: all 300ms ease;
  ${Container}:hover & {
    color: ${(props) => props.theme.palette.primary};
  }
`;
