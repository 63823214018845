import styled from "styled-components";

export const TextArea = styled.textarea`
  width: 100%;
  /* border: 1px solid #e2e2e2; */
  background: transparent;
  box-shadow: inset 0 -2px 0 0 ${(props) => props.theme.palette.gray};
  height: 240px;
  color: white;
  flex: 1;
  overflow-y: auto;
  outline: none;
  padding: 8px;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.background};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.background};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
  ::-webkit-scrollbar-corner {
    background: #555;
    cursor: pointer;
  }
`;
