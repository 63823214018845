import React from "react";
import styled from "styled-components";
import { LoginLayout } from "layouts";
import { Link } from "components";
import LogoImg from "assets/img/logo.png";
const Logo = styled.img`
  width: 200px;
  margin: 80px auto 0 auto;
`;

export const NotFoundPage = () => {
  return (
    <LoginLayout>
      <Logo src={LogoImg} alt="logo" />

      <LoginForm>
        <h2>Not Found</h2>
        <div className="d-flex mt-3">
          <Link className="mx-auto" href="/">
            Go to Home
          </Link>
        </div>
      </LoginForm>
    </LoginLayout>
  );
};
const LoginForm = styled.div`
  width: 428px;
  margin: auto auto 0 auto;
  color: white;
  h2 {
    font-size: 80px;
    text-align: center;
    margin-bottom: 80px;
  }
`;
