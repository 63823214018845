/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
} from "react";
import { fabric } from "fabric";
import styled from "styled-components";
import { Button } from "components";
import { usePostContext } from "contexts";

const convertToBtnPosition = (rect) => {
  const coords = rect.getCoords();
  const left = Math.min(...coords.map((item) => item.x));
  const right = Math.max(...coords.map((item) => item.x));
  const bottom = Math.max(...coords.map((item) => item.y));
  return {
    x: (left + right) / 2 - 108,
    y: bottom + 12,
  };
};
export const Cropper = forwardRef(
  (
    {
      source,
      onZoom = () => {},
      isLoading,
      onCrop = () => {},
      onCancelCrop = () => {},
      visible,
    },
    ref
  ) => {
    const { setToolbarState, toolBarState } = usePostContext();

    // Canvas information
    const [canvas, setCanvas] = useState("");
    const [backImgWidth, setBackImgWidth] = useState(0);
    const [backImgHeight, setBackImgHeight] = useState(0);
    const [backFitWidth, setBackFitWidth] = useState(0);
    const [backFitHeight, setBackFitHeight] = useState(0);
    const [backOffsetX, setBackOffsetX] = useState(0);
    const [backOffsetY, setBackOffsetY] = useState(0);
    const blockRef = useRef();
    var started = false;
    var x = 0;
    var y = 0;
    const currentOutlineColor = "red";

    const [btnPosition, setBtnPosition] = useState({ x: 0, y: 0 });
    const [isBtnVisible, setIsBtnVisible] = useState(false);

    function getBackImgSize(url) {
      var img = new Image();
      img.onload = function () {
        setBackImgWidth(this.width);
        setBackImgHeight(this.height);
      };
      img.src = url;
    }

    const handleCrop = (params) => {
      let frame = 0;
      if (source === "thumbnail") {
        frame = -1;
      } else if (source === "screenshot") {
        frame = "rscreen";
      }

      let cropObj;
      canvas.getObjects().forEach((rect) => {
        if (rect.id === "crop") {
          cropObj = rect;
        }
      });

      const coords = cropObj.getCoords();
      const left = Math.min(...coords.map((item) => item.x));
      const right = Math.max(...coords.map((item) => item.x));
      const top = Math.min(...coords.map((item) => item.y));
      const bottom = Math.max(...coords.map((item) => item.y));
      const rectW = backFitWidth * canvas.viewportTransform[0];
      const rectH = backFitHeight * canvas.viewportTransform[3];
      const backTop =
        backOffsetY * canvas.viewportTransform[0] + canvas.viewportTransform[5];
      const backLeft =
        backOffsetX * canvas.viewportTransform[3] + canvas.viewportTransform[4];

      onCrop({
        tl: `${((left - backLeft) / rectW).toFixed(5)},${(
          (top - backTop) /
          rectH
        ).toFixed(5)}`,
        br: `${((right - backLeft) / rectW).toFixed(5)},${(
          (bottom - backTop) /
          rectH
        ).toFixed(5)}`,
        frame,
      });
    };

    useEffect(() => {
      const canvi = new fabric.Canvas("canvas", {
        height: blockRef.current.offsetHeight,
        width: blockRef.current.offsetWidth,
        fireRightClick: true, // <-- enable firing of right click events
        stopContextMenu: true, // <--  prevent context menu from showing
      });

      fabric.Object.prototype.transparentCorners = false;
      fabric.Object.prototype.cornerColor = "white";
      fabric.Object.prototype.cornerStrokeColor = currentOutlineColor;
      fabric.Object.prototype.cornerStyle = "circle";
      fabric.Object.prototype.cornerSize = 8;

      setCanvas(canvi);

      return () => {};
    }, []);

    const init = async (toolBarState) => {
      const outlineRatio =
        blockRef.current.offsetWidth / blockRef.current.offsetHeight;
      const backRatio = backImgWidth / backImgHeight;
      let offsetX = 0,
        offsetY = 0,
        ratio = 1;
      if (outlineRatio >= backRatio) {
        ratio = blockRef.current.offsetHeight / backImgHeight;
        offsetY = 0;
        offsetX = (blockRef.current.offsetWidth - backImgWidth * ratio) / 2;
      } else {
        ratio = blockRef.current.offsetWidth / backImgWidth;
        offsetX = 0;
        offsetY = (blockRef.current.offsetHeight - backImgHeight * ratio) / 2;
      }
      setBackFitWidth(backImgWidth * ratio);
      setBackFitHeight(backImgHeight * ratio);
      setBackOffsetX(offsetX);
      setBackOffsetY(offsetY);

      // display dotted boundary when over rect
      canvas.on("mouse:over", function (e) {
        // handleCanvisObjectMouseOver(canvi, e);
      });
      // remove dotted boundary when over rect
      canvas.on("mouse:out", function (e) {
        if (e?.target) {
          e.target.set("strokeDashArray", null);

          if (canvas.status === "crop") {
            e.target.hoverCursor = "crosshair";
          } else {
            e.target.hoverCursor = "default";
          }

          canvas.renderAll();
        }
      });
      // zoom when mouse wheel
      canvas.on("mouse:wheel", function (opt) {
        var delta = opt.e.deltaY;
        var zoom = canvas.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;
        canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
        onZoom(zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();
        var vpt = this.viewportTransform;
        if (canvas.getHeight() > blockRef.current.offsetHeight) {
          vpt[4] = 200 - (1000 * zoom) / 2;
          vpt[5] = 200 - (1000 * zoom) / 2;
        }

        canvas.getObjects().forEach((rect) => {
          if (rect.id === "crop") {
            setBtnPosition(convertToBtnPosition(rect));
          }
        });
      });

      canvas.on("mouse:move", function (opt) {
        if (this.isDragging) {
          var e = opt.e;
          var vpt = this.viewportTransform;
          vpt[4] += e.clientX - this.lastPosX;
          vpt[5] += e.clientY - this.lastPosY;
          this.requestRenderAll();
          this.lastPosX = e.clientX;
          this.lastPosY = e.clientY;
          setIsBtnVisible(false);
        } else if (this.isCreating) {
          // Resize the newly created crop while mouse draging
          if (!started) {
            return false;
          }

          let mouse = canvas.getPointer(opt.e);

          let w = mouse.x - x,
            h = mouse.y - y;

          if (!w || !h) {
            return false;
          }

          let rect = canvas.getActiveObject();
          if (rect) {
            if (opt.e.shiftKey) {
              rect.set("width", Math.min(w, h)).set("height", Math.min(w, h));
            } else {
              rect.set("width", w).set("height", h);
            }
            rect.setControlsVisibility({ mtr: false });
          }

          canvas.renderAll();
        }
      });

      canvas.on("mouse:up", function (opt) {
        const evt = opt.e;
        this.setViewportTransform(this.viewportTransform);
        this.isDragging = false;
        this.selection = true;
        this.isCreating = false;
        var objects = canvas.getObjects();
        if (evt.button === 2) {
          if (started) {
            started = false;
          }
        }
        objects.forEach((rect) => {
          if (rect.id === "crop") {
            setBtnPosition(convertToBtnPosition(rect));
            setIsBtnVisible(true);
            canvas.setActiveObject(rect);
          }
        });
        canvas.requestRenderAll();
      });

      // pan when alt key & mouse down
      canvas.off("mouse:down");
      canvas.on("mouse:down", function (opt) {
        const evt = opt.e;
        if (evt.altKey === true) {
          this.isDragging = true;
          this.selection = false;
          this.lastPosX = evt.clientX;
          this.lastPosY = evt.clientY;
          setIsBtnVisible(false);
        } else if (evt.button === 2 && toolBarState === "crop") {
          setIsBtnVisible(false);
          var mouse = canvas.getPointer(evt);
          started = true;
          x = mouse.x;
          y = mouse.y;

          this.isCreating = true;
          canvas.getObjects().forEach((obj) => {
            canvas.remove(canvas.item(0));
          });
          var overlay = new fabric.Rect({
            left: 0,
            top: 0,
            width: canvas.width,
            height: canvas.height,
            fill: "#00000090",
            selectable: false,
            globalCompositeOperation: "source-over",
          });
          canvas.add(overlay);
          var rect = new fabric.Rect({
            width: 0,
            height: 0,
            left: x,
            top: y,
            stroke: currentOutlineColor,
            strokeWidth: 1,
            fill: "#451245",
            cornerColor: "white",
            globalCompositeOperation: "destination-out",
            cornerStrokeColor: currentOutlineColor,
            id: "crop",
          });
          rect.setControlsVisibility({ mtr: false });
          canvas.add(rect);
          canvas.renderAll();
          canvas.setActiveObject(rect);
        } else {
          canvas.getObjects().forEach((rect) => {
            if (rect.id === "crop") {
              canvas.setActiveObject(rect);
            }
          });
        }
      });

      canvas.on("selection:created", function (opt) {
        if (canvas.status === "move") {
          opt.target.hoverCursor = "move";
        }
      });

      canvas.on("selection:updated", function (opt) {
        if (canvas.status === "move") {
          opt.target.hoverCursor = "move";
        }
      });

      canvas.on("selection:cleared", function (opt) {
        console.log("selection:cleared", opt);
      });

      canvas.on("object:scaling", function (e) {
        setBtnPosition(convertToBtnPosition(e.target));
      });
      canvas.on("object:moving", function (e) {
        setBtnPosition(convertToBtnPosition(e.target));
      });
      canvas.on("object:modified", function (e) {
        setBtnPosition(convertToBtnPosition(e.target));
      });

      canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      canvas.setOverlayImage(
        source,
        function () {
          canvas.overlayImage && offsetY === 0
            ? canvas.overlayImage.scaleToHeight(canvas.height)
            : canvas.overlayImage.scaleToWidth(canvas.width);
          canvas.renderAll();
        },
        {
          top: offsetY,
          left: offsetX,
          lockMovementX: true,
          lockMovementY: true,
          lockRotation: true,
          selectable: false,
          globalCompositeOperation: "destination-atop",
        }
      );
    };

    useEffect(() => {
      if (source) {
        getBackImgSize(source);
      }
      return () => {};
    }, [source]);

    useEffect(() => {
      if (!isLoading && backImgWidth && backImgHeight) {
        init(toolBarState);
        if (toolBarState === "move") {
          canvas.getObjects().forEach((obj) => {
            canvas.remove(canvas.item(0));
          });
          setIsBtnVisible(false);
        }
      }
      return () => {};
    }, [isLoading, backImgWidth, backImgHeight, toolBarState]);

    useImperativeHandle(ref, () => ({
      resetZoom() {
        canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
        canvas.zoomToPoint({ x: 0, y: 0 }, 1);
        canvas.getObjects().forEach((rect) => {
          if (rect.id === "crop") {
            setBtnPosition(convertToBtnPosition(rect));
          }
        });
        canvas.renderAll();
      },
    }));

    return (
      <CanvasContainer ref={blockRef}>
        <ButtonGroup visible={isBtnVisible} x={btnPosition.x} y={btnPosition.y}>
          <Button
            width="100px"
            buttonTheme="dark"
            size="small"
            onClick={() => {
              setToolbarState("move");
              onCancelCrop();
            }}
          >
            Cancel
          </Button>
          <Button
            width="100px"
            className="ml-3"
            size="small"
            onClick={handleCrop}
          >
            Ok
          </Button>
        </ButtonGroup>
        <Canvas id="canvas" />
      </CanvasContainer>
    );
  }
);

const CanvasContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.palette.items};
  position: relative;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

const ButtonGroup = styled.div.attrs((props) => ({
  style: {
    marginLeft: `${props.x}px`,
    marginTop: `${props.y}px`,
  },
}))`
  position: absolute;

  z-index: 999;
  display: ${(props) => (props.visible ? "flex" : "none")};
`;
