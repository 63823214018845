import { API } from "utils";

export const getUserInfoApi = async () => {
  try {
    const response = await API().get("api/user_info");
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};
