import React, { useState, useEffect } from "react";
import styled from "styled-components";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { last } from "lodash";
import { getROIImage } from "api";
import { useLocation } from "@reach/router";
import ReactTooltip from "react-tooltip";
import HTMLParser from "react-html-parser";
import { abbreviateName } from "utils";

export const Product = ({
  brandName,
  price,
  productName,
  logo,
  roi,
  description,
  image,
  product,
}) => {
  const [isLoadingThumb, setIsLoadingThumb] = useState(false);
  const [thumbSrc, setThumbSrc] = useState("");
  const location = useLocation();
  const [desc, setDesc] = useState("");

  const fetchThumb = async (params) => {
    setIsLoadingThumb(true);
    const { success, payload } = await getROIImage(
      last(location.pathname.split("/")),
      params
    );
    if (success) {
      setThumbSrc(payload);
    } else {
      setThumbSrc("");
    }
    setIsLoadingThumb(false);
  };

  useEffect(() => {
    if (roi) {
      fetchThumb(roi);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roi]);

  useEffect(() => {
    let tmp = "";
    if (product) {
      if (product.brand) {
        if (product.assorted) {
          tmp += "These are assorted ";
        } else if (product.name[product.name.length - 1] === "s") {
          tmp += "These are ";
        } else if (
          ["A", "E", "I", "O", "U", "a", "e", "i", "o", "u"].includes(
            product.brand[0]
          )
        ) {
          tmp += "This is an ";
        } else {
          tmp += "This is a ";
        }
        tmp += product.brand;
        if (product.model) {
          tmp += product.model;
        }
        if (product.size_1) {
          tmp += product.size_1;
        }
        if (product.size_2) {
          tmp += ` - ${product.size_2}`;
        }
        if (product.size_12) {
          tmp += `${product.size_12}`;
        }
        if (product.size_3) {
          tmp += `${product.size_3}`;
        }
        if (product.size_4) {
          tmp += ` - ${product.size_4}`;
        }
        if (product.size_34) {
          tmp += `${product.size_34}`;
        }
        tmp += product.name;
      } else {
        if (product.assorted) {
          tmp += "These are assorted";
        } else if (product.name[product.name.length - 1] === "s") {
          tmp += "These are";
        } else {
          tmp += "This is a";
        }
        if (product.size_1) {
          tmp += product.size_1;
        }
        if (product.size_2) {
          tmp += ` - ${product.size_2}`;
        }
        if (product.size_12) {
          tmp += `${product.size_12}`;
        }
        if (product.size_3) {
          tmp += `${product.size_3}`;
        }
        if (product.size_4) {
          tmp += ` - ${product.size_4}`;
        }
        if (product.size_34) {
          tmp += `${product.size_34}`;
        }
        tmp += product.name;
      }
      if (product.description) {
        tmp += `<br/>The ${product.description}.`;
      }
      if (product.gtin) {
        tmp += `<br/>GTIN: ${product.gtin}`;
      } else if (product.upc) {
        tmp += `<br/>UPC: ${product.upc}`;
      }
      if (product.manufacturer) {
        tmp += `<br/>The manufacturer name is ${product.manufacturer}.`;
      }
      if (product.ingredients) {
        tmp += `<br/>Ingredients include ${product.ingredients}.`;
      }
      if (product.instructions) {
        tmp += `${product.instructions}.`;
      }
      if (product.country) {
        tmp += `<br/>This packaging is the ${
          product.confirmed ? "confirmed" : ""
        } ${product.country} version.`;
      }
      if (product.countryofsale && product.lang) {
        tmp += `<br/>This specific packaging is written in ${product.lang} and sold in ${product.countryofsale}.`;
      }
      if (product.company) {
        tmp += `<br/>The company contact information is: ${product.company}.`;
      }
    }
    setDesc(tmp);
    return () => {};
  }, [product]);

  return !roi ? (
    <Container>
      {image ? (
        <Pic img={image}></Pic>
      ) : (
        <TextPic>{abbreviateName(logo)}</TextPic>
      )}
      <Name>{logo}</Name>
    </Container>
  ) : (
    <Container>
      {isLoadingThumb ? (
        <RingLoader
          color="white"
          size={80}
          css={css`
            margin: auto;
          `}
        />
      ) : (
        <>
          <ProductImg
            src={`data:image/jpeg;base64,${thumbSrc}`}
            data-for="product-desc"
            data-tip={desc}
          />
          <div className="mt-2 text-center">{HTMLParser(desc)}</div>
          <ReactTooltip
            id="product-desc"
            className="brand-tooltip"
            place="top"
            multiline
            effect="solid"
            brand-tooltip="#000"
          />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 180px;
  min-height: 180px;
  margin: 8px;
`;
const Pic = styled.div`
  width: 180px;
  height: 180px;
  position: relative;
  background: url(${(props) => props.img}) #0a0a0a;
  background-size: cover;
  background-position: center;
  border: 1px solid #e2e2e2;
`;

const TextPic = styled.div`
  width: 180px;
  height: 180px;
  line-height: 180px;
  font-size: 80px;
  text-align: center;
  position: relative;
  background: #0a0a0a;
  background-size: cover;
  background-position: center;
  border: 1px solid #e2e2e2;
`;
// const Percent = styled.div`
//   position: absolute;
//   background: black;
//   color: white;
//   height: 32px;
//   line-height: 32px;
//   font-size: 20px;
//   padding: 0 8px;
//   top: 8px;
//   left: 8px;
// `;
const Name = styled.div`
  margin-top: 8px;
  color: white;
  font-size: 14px;
`;
// const Brand = styled.div`
//   margin-top: 8px;
//   color: #707070;
//   font-size: 16px;
//   display: flex;
//   justify-content: space-between;
// `;

const ProductImg = styled.img`
  width: 180px;
  height: 180px;
  object-fit: contain;
  border: 1px solid #e2e2e2;
  background: #0a0a0a;
`;
