import React from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { format } from "date-fns";
// import { Button } from "components";
import { Section } from "./../components";

export const MetadataSection = ({ isLoading, post }) => {
  return isLoading ? (
    <Section title="MetaData" style={{ minHeight: 195 }}>
      <SkeletonTheme color="#202020" highlightColor="#444">
        <Skeleton count={3} />
      </SkeletonTheme>
    </Section>
  ) : (
    <Section title="MetaData" style={{ minHeight: 195 }}>
      <Container className="row">
        {post?.user?.name && (
          <div className="col-md-6 d-flex">
            <Label>Uploaded By:</Label>
            <Value>{post?.user?.name}</Value>
          </div>
        )}
        {/* {post?.source && (
          <div className="col-md-6 d-flex">
            <Label>Source:</Label>
            <Value>
              <a href={post?.source} target="_blank" rel="noreferrer">
                Link
              </a>
            </Value>
          </div>
        )} */}
        {post?.data?.pre?.tiktok?.video?.pageProps?.id && (
          <div className="col-md-6 d-flex">
            <Label>TikTok:</Label>
            <Value>
              <a
                href={post?.data?.pre?.tiktok?.video?.pageProps?.id}
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
            </Value>
          </div>
        )}
        {post?.cert?.meta?.published && (
          <div className="col-md-6 d-flex">
            <Label>Published:</Label>
            <Value>{post?.cert?.meta?.published}</Value>
          </div>
        )}
        {post?.data?.pre?.first_run && (
          <div className="col-md-6 d-flex">
            <Label>Sighted:</Label>
            <Value>{post?.data?.pre?.first_run}</Value>
          </div>
        )}
        {post?.cert?.meta?.exif && post?.cert?.meta?.exif[0]?.CreationDate ? (
          <div className="col-md-6 d-flex">
            <Label>Created:</Label>
            <Value>{post.cert.meta.exif[0]["CreationDate"]}</Value>
          </div>
        ) : post?.cert?.meta?.exif &&
          post?.cert?.meta?.exif[0]?.FileModifyDate ? (
          <div className="col-md-6 d-flex">
            <Label>Created:</Label>
            <Value>{post.cert.meta.exif[0]["FileModifyDate"]}</Value>
          </div>
        ) : null}
        {post?.creation_time && (
          <div className="col-md-6 d-flex">
            <Label>Uploaded:</Label>
            <Value>
              {format(new Date(post?.creation_time), "MM/dd/yyyy hh:mm:ss")}
            </Value>
          </div>
        )}
        {post?.cert?.meta?.exif && post?.cert?.meta?.exif[0]?.ModifyDate ? (
          <div className="col-md-6 d-flex">
            <Label>Modified:</Label>
            <Value>{post.cert.meta.exif[0]["ModifyDate"]}</Value>
          </div>
        ) : post?.cert?.meta?.exif &&
          post?.cert?.meta?.exif[0]?.FileModifyDate ? (
          <div className="col-md-6 d-flex">
            <Label>Modified:</Label>
            <Value>{post.cert.meta.exif[0]["FileModifyDate"]}</Value>
          </div>
        ) : null}
        {post?.cert_time && (
          <div className="col-md-6 d-flex">
            <Label>Certified:</Label>
            <Value>
              {format(new Date(post?.cert_time), "MM/dd/yyyy hh:mm:ss")}
            </Value>
          </div>
        )}
        {post?.thumb_time && (
          <div className="col-md-6 d-flex">
            <Label>Viewed:</Label>
            <Value>
              {format(new Date(post?.thumb_time), "MM/dd/yyyy hh:mm:ss")}
            </Value>
          </div>
        )}
        <div className="col-md-6 d-flex">
          <Label>ID:</Label>
          <Value>{post?.post_hash}</Value>
        </div>
        <div className="col-md-6 d-flex">
          <Label>Format:</Label>
          <Value>{post?.format}</Value>
        </div>
        <div className="col-md-6 d-flex">
          <Label>Filesize:</Label>
          <Value>{post?.size}b</Value>
        </div>

        <div className="col-md-6 d-flex">
          <Label>Filesize:</Label>
          <Value>{post?.size}b</Value>
        </div>
        {post?.width && post?.height && post?.fps && post?.duration && (
          <div className="col-md-6 d-flex">
            <Label>Dimensions:</Label>
            <Value>{`${post?.width}x${post?.height}, ${post?.fps?.toFixed(
              2
            )}fps, ${post?.duration?.toFixed(2)}s`}</Value>
          </div>
        )}
        {post?.pixels && (
          <div className="col-md-6 d-flex">
            <Label>Pxiels:</Label>
            <Value>{post?.pixels}</Value>
          </div>
        )}
        {post?.container && (
          <div className="col-md-6 d-flex">
            <Label>Container:</Label>
            <Value>{post?.container}</Value>
          </div>
        )}
        {post?.decoder && (
          <div className="col-md-6 d-flex">
            <Label>Decoder:</Label>
            <Value>{post?.decoder}</Value>
          </div>
        )}
        {post?.audio && (
          <div className="col-md-6 d-flex">
            <Label>Audio:</Label>
            <Value>{post?.audio}</Value>
          </div>
        )}
        {/* <div className="col-12 d-flex mt-3">
          <Button buttonTheme="dark" size="small" width="90px">
            Raw
          </Button>
          <Button
            buttonTheme="dark"
            size="small"
            width="120px"
            className="ml-4"
          >
            Thumbnail
          </Button>
        </div> */}
      </Container>
    </Section>
  );
};

const Label = styled.div`
  color: ${(props) => props.theme.palette.primaryHover};
  width: 120px;
`;

const Value = styled.div`
  color: ${(props) => props.theme.palette.white};
  text-overflow: ellipsis;
  width: 200px;
`;

const Container = styled.div`
  a {
    color: white;
    transition: all 300ms ease;
    &:hover {
      color: ${(props) => props.theme.palette.primary};
    }
  }
`;
