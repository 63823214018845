import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

export const ExpandableFileContent = ({ children, className, open }) => {
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setContentHeight(contentRef.current.clientHeight + 2);
    return () => {};
  });

  return (
    <Content open={open} className={className} maxHeight={contentHeight}>
      <div ref={contentRef}>{children}</div>
    </Content>
  );
};

const Content = styled.div`
  overflow: hidden;
  transition: all 300ms ease;
  max-height: ${(props) => (props.open ? `${props.maxHeight}px` : 0)};
`;
