import {
  GET_USER_INFO,
  LOGIN,
  LOGOUT,
  SYNC_REQUEST,
  SET_USER_MODE,
  GET_APP_INFO,
} from "./../types";

export default function AuthReducer(state, action) {
  switch (action.type) {
    case GET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
        isLoading: false,
      };
    case GET_APP_INFO:
      return {
        ...state,
        appInfo: action.payload,
        isLoading: false,
      };
    case SET_USER_MODE:
      return {
        ...state,
        userMode: action.payload,
      };
    case LOGIN:
      return {
        isLogined: true,
        isLoading: false,
        userInfo: {},
      };
    case LOGOUT:
      return {
        isLogined: false,
        isLoading: false,
        userInfo: {},
      };

    case SYNC_REQUEST:
      return action.payload;
    default:
      return state;
  }
}
