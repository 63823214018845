export const convertString2Time = (params) => {
  if (!params) return "00:00";
  const make2Digits = (params) => {
    if (params < 10) return `0${params}`;
    return params;
  };
  const hh = Math.floor(params / 3600);
  const mm = Math.floor((params % 3600) / 60);
  const ss = Math.floor(params % 60);
  return `${hh > 0 ? `${make2Digits(hh)}:` : ""}${`${make2Digits(
    mm
  )}:${make2Digits(ss)}`}`;
};
