import React from "react";
import styled from "styled-components";

export const Tab = ({ values, value, onChange }) => {
  return (
    <Container>
      {values.map((val) => (
        <TabElement
          key={val}
          active={val === value}
          onClick={() => onChange(val)}
        >
          {val}
        </TabElement>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;
const TabElement = styled.div`
  color: ${(props) => (props.active ? props.theme.palette.primary : "white")};
  font-size: 24px;
  transition: all 300ms ease;
  cursor: pointer;
  flex: 1;
  text-align: center;
  box-sizing: content-box;
  border-bottom: ${(props) =>
    props.active
      ? `2px solid ${props.theme.palette.primary}`
      : `1px solid ${props.theme.palette.gray}`};
`;
