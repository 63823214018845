import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ScrollView, SearchInput } from "components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useInput } from "hooks";
import { Box, Event } from "./../components";

export const EventsTimeline = ({
  isLoading,
  data,
  onEventClick = () => {},
}) => {
  const [events, setEvents] = useState([]);
  const search = useInput("");

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      let initEvents = [];
      for (const frame in data) {
        initEvents = [
          ...initEvents,
          ...data[frame].map((event) => {
            return { ...event, frame };
          }),
        ];
      }
      setEvents(initEvents);
    }
    return () => {};
  }, [data]);

  useEffect(() => {
    return () => {};
  }, [search.value]);

  return (
    <Container>
      <SearchInput placeholder="Filter Events Timeline" {...search} />
      <Events>
        {isLoading ? (
          <SkeletonTheme color="#202020" highlightColor="#444">
            <div className="d-flex flex-column">
              <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
              <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
              <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
              <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
              <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
              <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
              <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
              <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
              <Skeleton className="my-2 mx-auto" height={24} width={"100%"} />
            </div>
          </SkeletonTheme>
        ) : (
          <>
            {events
              .filter((event) =>
                event.d.toLowerCase().includes(search.value.toLowerCase())
              )
              .map((event, index) => (
                <Event
                  index={index + 1}
                  name={event.d}
                  key={`${index}key`}
                  type={event.t}
                  frame={event.frame}
                  onClick={() => onEventClick(event.frame)}
                />
              ))}
          </>
        )}
      </Events>
    </Container>
  );
};

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 320px;
`;
const Events = styled(ScrollView)`
  margin-top: 16px;
  padding-right: 8px;
`;
