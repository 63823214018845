import styled from "styled-components";

export const Text = styled.div`
  font-size: ${(props) => props.theme.font.size.m};
  line-height: ${(props) => props.theme.font.size.m};
  color: white;
`;

export const Desc = styled.div`
  font-size: ${(props) => props.theme.font.size.xs};
  line-height: ${(props) => props.theme.font.size.xs};
  color: ${(props) => props.theme.palette.secondary};
`;
